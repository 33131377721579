import React, { Component } from 'react';
import Ansprechpartner from './index';
import { connect } from 'react-redux';
import { ROOT_URL } from '../../../../config';
import { fetch } from '../../../../redux/actions';

class AnsprechpartnerRender extends Component {
  componentDidMount() {
    if(!this.props.loaded) {
      this.props.fetch('fetch_ansprechpartner', `${ROOT_URL}/api/collections/get/ansprechpartner?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  renderHelper = () => {
    // if data isnt loaded exit the function
    if(!this.props.loaded) { return }
    // if data is loaded render Produkts
    return this.props.ansprechpartner.map((data, i) => {
      return (
        <div key={i} className="col-lg-4 col-md-4 col-ms-6 xy-hidden">
          <Ansprechpartner
            image={data.image.path}
            name={data.name}
            bezeichnung={data.bezeichnung}
            tel={data.tel}
            mail={data.mail}
          />
        </div>
      )
    })
  }

  render() {
    return (
      <>
        <div className="row">
          {this.renderHelper()}
        </div>
      </>
    );
  }
}


const mapStateToProps = (data) => {
  return {
    loaded: data.loaded.ansprechpartner,
    ansprechpartner: data.ansprechpartner,
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(AnsprechpartnerRender)
