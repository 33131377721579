import React from 'react';

import { PageContainer, HeadlineContainer } from '../reusable/styled/container';
import { Title, Paragraph, SubTitle } from '../reusable/styled/fonts';

const Agb = () => (
  <PageContainer>
    <div className="pt container-big full-visible">
      <div className="row pt">
        <div className="col-lg-12">
          <HeadlineContainer>
            <Title>Allgemeine Verkaufs- und Lieferbedingungen</Title>
            </HeadlineContainer>
          <SubTitle>Sonnenalm Bäuerlicher Milchhof e.Gen., Milchstraße 1, 9373 Klein St. Paul</SubTitle>


          <div className="container-big pt">
            <div className="container-big nopl">
              <SubTitle className="bold ">1. Geltungsbereich</SubTitle>
            </div>
            <Paragraph>Diese Allgemeinen Verkaufs- und Lieferbedingungen gelten für alle unsere Lieferungen von Waren und – sinngemäß – für das Erbringen von Leistungen durch uns, auch wenn diese Lieferungen bzw. Leistungen ohne Verwendung oder ausdrückliche Bezugnahme auf diese Allgemeinen Verkaufs- und Lieferbedingungen erfolgen. Mit Bestellung bzw. spätestens mit Empfang der Ware bzw. Leistung anerkennt der Vertragspartner diese Allgemeinen Verkaufsund Lieferbedingungen.</Paragraph>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className="bold ">2. Angebot, Vertragsabschluss</SubTitle>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">a)</span> Unsere Angebote sind zur Gänze freibleibend und unverbindlich. Bestellungen des Vertragspartners werden erst durch unsere schriftliche Auftragsbestätigung oder durch Lieferung bzw. Leistung angenommen. Wir sind berechtigt, Bestellungen auch nur zum Teil anzunehmen oder ohne Angabe von Gründen abzulehnen.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">b)</span> Abänderungen oder Nebenabreden zu diesen Allgemeinen Verkaufs- und Lieferbedingungen bedürfen zu ihrer Gültigkeit unserer schriftlichen Bestätigung und gelten nur für den jeweiligen einzelnen Geschäftsfall. Abweichenden Vertragsbedingungen des Vertragspartners wird ausdrücklich widersprochen; sie werden nicht angewendet, unabhängig davon, ob sie vor oder beim Vertragsabschluss oder erst bei Vertragsabwicklung vorgelegt oder übersendet werden. Auch das Übersenden einer Auftragsbestätigung durch uns gilt nicht als Anerkennung der Vertragsbedingungen des Vertragspartners.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">c)</span> Abbildungen, Zeichnungen, Markenbezeichnungen oder dergleichen sind unverbindlich. Broschüren, Kataloge sowie einem Angebot beigefügte Unterlagen dürfen ohne unsere Zustimmung weder vervielfältigt, verbreitet noch veröffentlicht werden.</Paragraph>
            </div>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className="bold ">3. Ereignisse unabwendbarer Art – Lieferverzögerungen & Ausfälle</SubTitle>
            </div>
            <Paragraph>Im Fall von Ereignissen, die durch höhere Gewalt herbeigeführt werden, die sich für unsere Lieferanten und uns als unabwendbar darstellen, haben wir das Recht, entweder den Zeitpunkt der Lieferung oder der Leistung ohne Benachrichtigung aufzuschieben oder vom Vertrag zurückzutreten, ohne dass dadurch für uns Schadenersatzverpflichtungen entstehen. Es gelten beispielhaft folgende Ereignisse als unabwendbar: Streik, Aussperrungen, Aufruhr, Revolution, Mobilmachung, Krieg, Epidemie, hoheitliche Anordnungen, Maschinenausfälle oder Störungen der EDV, Brände, Ausfall von Zulieferungen, Umwelteinflüsse und Umweltverschmutzungen, Zoll- oder Steuererhöhungen oder andere, die Lieferung erschwerende Fälle höherer Gewalt. In diesem Fall sind wir berechtigt, ohne Ersatzleistung vom Vertrag zurückzutreten.</Paragraph>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className="bold ">4. Lieferung, Lieferzeit</SubTitle>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">a)</span> Die Angabe von Lieferterminen erfolgt unverbindlich. Die Nichteinhaltung der Liefertermine berechtigt den Vertragspartner allerdings erst dann zur Geltendmachung der ihm gesetzlich zustehenden Rechte, wenn wir trotz schriftlicher Setzung einer mindestens vierwöchigen Nachfrist nicht liefern.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">b)</span> Die Lieferfrist wird durch alle vom Parteiwillen unabhängigen Umstände, wie z. B. nicht rechtzeitige Belieferung durch die Vorlieferanten, Fälle höherer Gewalt, unvorhersehbare Betriebsstörungen, behördliche Eingriffe, Transport und Verzollungsverzug, Transportschäden, Energie-, Material- und Rohstoffmangel und Arbeitskonflikte, um die Dauer der Hinderung verlängert.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">c)</span> Soweit Teillieferungen möglich sind, sind sie auch rechtlich zulässig. Jede Teillieferung gilt als eigenes Geschäft und kann von uns gesondert in Rechnung gestellt werden.</Paragraph>
            </div>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className="bold ">5. Preise</SubTitle>
            </div>
            <Paragraph>Unsere Preise sind Nettobeträge, enthalten keine Umsatzsteuer und sind in EURO (€) angegeben. Die Preise enthalten keine Verpackungs- und Versandkosten und keinerlei Nebenleistungen. Mangels gegenteiliger Vereinbarung liegt dem Vertrag die zum Zeitpunkt des Vertragsabschlusses geltende Preisliste zu Grunde. Die in unserer Preisliste angeführten Preise sind freibleibend. Es wird der Preis gemäß der am Tag der Lieferung geltenden Preisliste verrechnet.</Paragraph>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className=" bold ">6. Zahlungsbedingungen</SubTitle>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">a)</span> Unsere Rechnungen sind unabhängig vom Eingang der Ware prompt nach Erhalt der Rechnung ohne jeden Abzug fällig. Ein Skonto wird nur bei ausdrücklicher schriftlicher Vereinbarung gewährt. Bei Einräumung eines Skontos gilt als Zahlungstag der Tag, an dem das Geld bei uns eingegangen ist oder dem Geschäftskonto gutgeschrieben wird. Wenn Teilrechnungen gelegt werden, steht ein Skonto nur dann zu, wenn sämtliche Teilrechnungen der Gesamtlieferung innerhalb der Skontofrist bezahlt werden.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">b)</span> Wechsel werden nicht angenommen und generell retourniert. Schecks werden nur zahlungshalber entgegengenommen und gelten erst nach vorbehaltslosem Einlösen als Zahlung; sämtliche Spesen trägt der Vertragspartner.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">c)</span> Eine Zahlung ist nur dann wirksam und schuldbefreiend, wenn sie an einen unserer Inkassobevollmächtigten geleistet wird. Der Vertragspartner hat sich vor Zahlung die Inkassovollmacht vorweisen zu lassen.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">d)</span> Der Vertragspartner verzichtet, allfällige Gegenforderungen mit Entgeltansprüchen unsererseits aufzurechnen. Es ist ihm auch nicht erlaubt, fällige Rechnungsbeträge oder sonstige Abzüge, aus welchem Grund immer, zurückzubehalten.</Paragraph>
            </div>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className="bold ">7. Zahlungsverzug des Vertragspartners</SubTitle>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">a)</span> Bei verspäteter Zahlung berechnen wir – sofern uns nicht höhere Kosten entstehen – beginnend mit dem 15. Tag ab Rechnungsdatum Verzugszinsen in der Höhe von 1 Prozent p. m. Die mit der Einbringlichmachung verbundenen Mahn-, Auskunfts- und sonstigen Kosten trägt der Vertragspartner.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">b)</span> Der Vertragspartner ist bei Zahlungsverzug verpflichtet, sämtliche, durch seinen Zahlungsverzug anfallenden Mahn- und Anspruchsverfolgungskosten (dazu gehören auch Kosten außergerichtlicher Anspruchsverfolgung und insbesondere die Inanspruchnahme eines Inkassodienstes) zu ersetzen.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">c)</span> Eingehende Zahlungen werden auf Forderungen nach unserer Wahl und vorab auf die dazugehörigen angelaufenen Nebengebühren (Zinsen und Mahnkosten) angerechnet.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">d)</span> Tritt Zahlungsverzug ein, verlieren die gewährten Skonti und Rabatte ihre Wirksamkeit. Dies gilt auch, wenn der Zahlungsverzug aus einer anderen Geschäftsbeziehung der Vertragsparteien herrührt.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">e)</span> Auch bei Teilbarkeit des Vertragsgegenstands steht es uns offen, vom gesamten Vertrag zurückzutreten.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">f)</span> Wir haben das Recht, vor der Zahlung aller fälligen Rechnungsbeträge (einschließlich Verzugszinsen und Mahnkosten) die Erfüllung eigener weiterer, aus dieser oder einer anderen Geschäftsbeziehung zum Vertragspartner resultierender Verpflichtungen zu verweigern.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">g)</span> Bereits die Einleitung eines Insolvenzverfahrens über den Vertragspartner oder die Anzeige der Einstellung der Zahlungen durch den Vertragspartner berechtigt uns zur vorzeitigen Beendigung des Vertragsverhältnisses und von der weiteren Vertragserfüllung abzusehen.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">h)</span> Bei Vertragsrücktritt durch uns ist der Vertragspartner verpflichtet, den bereits übersendeten Vertragsgegenstand unverzüglich zu übersenden oder auf Verlangen abholbereit zu halten. Der Vertragspartner trägt Gefahr und Kosten der Rückgabe bis zum Einlangen des Vertragsgegenstandes in unserem Geschäftslokal.</Paragraph>
            </div>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className="bold ">8. Eigentumsvorbehalt</SubTitle>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">a)</span> Sämtliche gelieferte Waren bleiben bis zur Bezahlung des Kaufpreises samt Nebenspesen – bei laufender Rechnung von Saldoforderungen, aus welcher Lieferung auch immer – unser Eigentum.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">b)</span> Der Vertragspartner ist berechtigt, die von uns gelieferte Ware in ordnungsgemäßen Geschäftsbetrieb an Dritte weiterzuveräußern. Er tritt bereits bei Vertragsabschluss alle ihm zustehenden Ansprüche gegen seine Abnehmer mit allen Nebenrechten zur Einziehung an uns ab, bleibt jedoch zur Einziehung berechtigt, so lange er sich nicht uns gegenüber in Verzug befindet. Zur Einhaltung der geforderten Publizität ist vom Vertragspartner ein Vermerk der Abtretung in seinen Büchern zu setzen. Der Vertragspartner hat uns auch alle Unterlagen und Informationen zu geben, die zur Geltendmachung unserer Rechte erforderlich sind. Gelangt ein derartiger abgetretener Rechnungsbetrag an Dritte, so ist der Vertragspartner verpflichtet, diesen Betrag vom Dritten zurückzufordern und ihn an uns auszufolgen.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">c)</span> Zur Besichtigung der Vorbehaltsware sichert uns der Vertragspartner jederzeit den Zutritt zu seinem Betrieb zu. Kommt der Vertragspartner mit seinen Zahlungsverpflichtungen in Verzug, wird ein Insolvenzverfahren über sein Vermögen beantragt oder eröffnet oder verstößt der Vertragspartner gegen sonstige Vertragspflichten, so sind wir – nach unserer Wahl unter Aufrechterhaltung des Vertrags – berechtigt, die Herausgabe der Vorbehaltsware zu verlangen, diese abzuholen und/oder sicherungsweise abgetretene Forderungen einzuziehen.</Paragraph>
            </div>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className="bold ">9. Erfüllung und Gefahrenübergang</SubTitle>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">a)</span> Die Gefahr geht mit Übergabe der Ware an den Spediteur oder Frachtführer, spätestens jedoch mit dem Verlassen unseres Lagers auf den Vertragspartner über. Dies gilt auch im Fall der Lieferung durch uns frei Bestimmungsort mit eigenem oder fremdem Fahrzeug.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">b)</span> Die Lieferung erfolgt mangels besonderer Weisung des Vertragspartners nach bestem Ermessen und ohne Gewähr für die Wahl der schnellsten und billigsten Versendung. Verpackungsmaterial wird nicht zurückgenommen.</Paragraph>
            </div>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className=" bold ">10. Mängelrüge und Gewährleistung</SubTitle>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">a)</span> Der Vertragspartner, der nicht Verbraucher im Sinne des § 1 KSchG ist, hat bei sonstigem Verlust aller Gewährleistungs- und Schadenersatzansprüche die gelieferte Ware nach Erhalt unverzüglich auf Vollständigkeit, Richtigkeit und sonstige Mängelfreiheit zu überprüfen und eventuelle Mängel unverzüglich schriftlich zu rügen. Dessen ungeachtet hat der Vertragspartner durch den Transport hervorgerufene Beschädigungen des Vertragsgegenstands auf den Lieferpapieren zu vermerken und innerhalb von 24 Stunden ab Erhalt des Vertragsgegenstands unter Beschreibung der Mängel schriftlich anzuzeigen. Wenn Waren unmittelbar an Dritte versandt werden, beginnen die Fristen für die Untersuchung und Rügeverpflichtung mit Einlangen der Ware beim Dritten.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">b)</span> Für die Verwendbarkeit für einen bestimmten Gebrauch leisten wir nur bei ausdrücklicher Zusage Gewähr.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">c)</span> Unsere Gewährleistung beschränkt sich nach unserer Wahl auf die Lieferung von Ersatzware gleicher Art und Menge oder Verbesserung bzw. Nachtrag des Fehlenden. Der Vertragspartner hat nicht das Recht auf Wandlung. Bei aufgrund von Spezifikationen und Anweisungen des Vertragspartners erbrachten Leistungen leisten wir nur für die bedingungsgemäße Ausführung Gewähr. Die Gewährleistungsfrist beginnt durch Lieferung von Ersatzware bzw. Verbesserung nicht neu zu laufen. Der Anspruch auf Preisminderung besteht für Vertragspartner, die Verbraucher im Sinne des § 1 KSchG sind, nur dann, wenn wir tatsächlich nicht verbessern.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">d)</span> Auch dann, wenn vom Vertragspartner Schadenersatzansprüche geltend gemacht werden, hat uns dieser die Gelegenheit zu geben, selbst den vertragsgemäßen Zustand herzustellen.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">e)</span> Alle anderen Ansprüche aufgrund einer nachweisbar mangelhaft erbrachten Leistung (Anspruch auf entgangenen Gewinn, Anspruch auf Ersatz von Aufwendungen oder andere Mangelfolgeschäden) sind ausgeschlossen.</Paragraph>
            </div>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className=" bold ">11. Schadenersatz</SubTitle>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">a)</span> Schadenersatzansprüche des Vertragspartners, aus welchem Rechtsgrund immer, insbesondere wegen Verzugs, Unmöglichkeit der Leistung, positiver Forderungsverletzung, Verschulden bei Vertragsabschluss, Mangelfolgeschadens, Mängeln oder wegen unerlaubter Handlungen, sind ausgeschlossen, soweit sie nicht auf Vorsatz oder krasser grober Fahrlässigkeit unsererseits beruhen. Für Verbraucher gilt dies bloß in Bezug auf Sachschäden. Zudem haften wir nicht für entgangenen Gewinn oder ideelle Schäden, die dem Vertragspartner entstanden sind.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">b)</span> Dem Vertragspartner, der nicht Verbraucher im Sinne des KSchG ist, obliegt auch der Beweis für unser Verschulden. § 1298 ABGB wird damit nicht angewendet.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">c)</span> Bei einem Verstoß des Vertragspartners gegen eine Hauptpflicht des Vertrags (insbesondere die zeitgerechte Übernahme des Vertragsgegenstands und bei [Teil-] Entgeltzahlungsverzug) ist dieser jedenfalls, selbst wenn kein Verschulden vorliegt, verpflichtet, uns einen Betrag in der Höhe von 20 Prozent des Gesamtentgelts zu bezahlen. Der Nachweis eines Schadens, der uns erwachsen ist, muss unsererseits nicht erbracht werden. Eine richterliche Mäßigung der Konventionalstrafe im Sinne des § 1336 Abs. 2 ABGB findet – mit Ausnahme für Verbraucher im Sinne des § 1 KSchG – nicht statt. Über den genannten Betrag hinausgehende Schäden sind zu ersetzen.</Paragraph>
            </div>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className=" bold ">12. Anwendbares Recht, Erfüllungsort, Gerichtsstand</SubTitle>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">a)</span> Auf die Rechtsbeziehungen zwischen den Vertragspartnern ist ausschließlich österreichisches Recht anzuwenden.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">b)</span> Erfüllungsort ist Klein St. Paul. Gerichtsstand ist St. Veit an der Glan.</Paragraph>
            </div>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className="bold ">13. Sonstiges</SubTitle>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">a)</span> Der Vertragspartner stimmt der Verarbeitung der auf das Geschäftsverhältnis bezogenen Daten zu.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph><span className="bold">b)</span> Eine Übertragung der Rechte aus dem mit uns abgeschlossenen Vertrag an Dritte ist ohne unsere schriftliche Genehmigung nicht gestattet. c) Bei rechtlicher Unwirksamkeit einzelner Punkte der allgemeinen Verkaufs- und Lieferbedingungen bleiben die übrigen Bestimmungen und die auf ihrer Grundlage geschlossenen Verträge aufrecht. Die unwirksame Bestimmung ist durch eine wirksame, die ihr dem Sinn und Zweck nach am nächsten kommt, zu ersetzen.</Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageContainer>
);


export default Agb;
