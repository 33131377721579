import React, { Component } from "react";
import { ROOT_URL } from "../../../../config";
import { fetch } from "../../../../redux/actions";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { PageContainer } from "../../../reusable/styled/container";
import { Header } from "../../../reusable/styled/header";

import BauernRender from "./bauern-render";

import Logo from "../../../../media/logos/Logo.svg";

import BigImg from "../../../../media/header/header_unsere-Bauern_big.jpg";
import MiddleImg from "../../../../media/header/header_unsere-Bauern_medium.jpg";
import SmallImg from "../../../../media/header/header_unsere-Bauern_small.jpg";

class Bauern extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      this.props.fetch(
        "fetch_unsereBauern",
        `${ROOT_URL}/api/singletons/get/unsereBauern?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
  }
  render() {
    return (
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Milchbauern der Sonnenalm | Unsere Bauern - Sonnenalm</title>
          <meta
            name="description"
            content="Es sind unsere Bauernfamilien, die 365 Tage im Jahr  für Milch in reinster Qualität sorgen - Respekt!"
          />
          <link
            rel="canonical"
            href="http://www.milcherlebniswelt.at/ueber-uns/unsere-bauern"
          />
        </Helmet>

        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Unsere Bauern"
          ></Header>
        </div>

        <div className="container-big" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.unsereBauern.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.unsereBauern.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.unsereBauern.text
                }}
              ></div>
            )}
          </div>
        </div>

        <div className="container-big" style={{ marginBottom: "25px" }}>
          <div className="row">
            <BauernRender />
          </div>
        </div>


      </PageContainer>
    );
  }
}

const mapStateToProps = data => {
  return {
    loaded: data.loaded.unsereBauern,
    unsereBauern: data.unsereBauern
  };
};

export default connect(mapStateToProps, { fetch })(Bauern);
