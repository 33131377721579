import React, { Component } from 'react';
import Slider from "react-slick";

export default class SliderContainer extends Component {
  state = {
    width: window.innerWidth,
    images: null
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    //window.addEventListener("scroll", this.scroll);
    this.setState({
      images: this.state.width > 1200 ? this.props.imagesB : this.state.width > 414 ? this.props.imagesM : this.props.imagesS,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    //window.removeEventListener("scroll", this.scroll);
  }

  resize = () => {
    // sets the image to be used on different screen sizes
    const useImage = this.state.width > 1200 ? this.props.imagesB : this.state.width > 414 ? this.props.imagesM : this.props.imagesS;
    this.setState({
      width: window.innerWidth,
      images: useImage,
    })
  }

  // scroll funtion is not in use in this version
  scroll = () => {
    const header = this.refs.header;
    const headerPos = header.getBoundingClientRect();

    const myOpacityBg = 1 + headerPos.top / 700;

    this.setState({
      opacityBg: myOpacityBg,
    })

    header.style.opacity = this.state.opacityBg;
  }

  renderHelper = () => {
    if(this.state.images === null) {return}
    return this.state.images.map((data, i) => {
      // const text = this.props.text;
      // const ball = this.props.ball;
      return(
        <div key={i} className=" home-slider">
          <img style={{width: '100%', height: 'auto'}} alt="" className="home-slider-img" src={data} />
        </div>
      )
    })

  }

  render() {
    const settings = {
      fade: true,
      dots: true,
      arrow: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
    };
    return (
      <div ref="header" className={this.props.className}>
        <Slider {...settings} >
          {this.renderHelper()}
        </Slider>
      </div>
    );
  }
}
