import React from 'react';

import { SubTitle } from '../../../../reusable/styled/fonts';

export const ModalBoxContent = (props) => {
  const renderHelper = function() {
    const { naehrwerte, allergene } = props;
    const isAllergene = allergene ? "Ja" : "Nein";
    if(naehrwerte !== undefined) {
      return (
        <div className="container-middle-small modallist-container ">
          <div className="padding-modal-produkt">
            <div className="closeBtn"></div>
            <SubTitle>{naehrwerte.Bennenung}</SubTitle>
            <p className="bb bold">pro 100g</p>
            <div className="row">
                <p className="col-s-6 br">Energie</p>
                <p className="col-s-6">{naehrwerte.Energie}</p>
                <p className="col-s-6 br">Fett</p>
                <p className="col-s-6">{naehrwerte.Fett}</p>
                <p className="col-s-6 br">davon gesättigte Fettsäuren</p>
                <p className="col-s-6">{naehrwerte.Fettsäuren}</p>
                <p className="col-s-6 br">Kohlenhydrate</p>
                <p className="col-s-6">{naehrwerte.Kohlenhydrate}</p>
                <p className="col-s-6 br">davon Zucker</p>
                <p className="col-s-6">{naehrwerte.Zucker}</p>
                <p className="col-s-6 br">Ballaststoffe</p>
                <p className="col-s-6">{naehrwerte.Ballaststoffe}</p>
                <p className="col-s-6 br">Eiweiß</p>
                <p className="col-s-6">{naehrwerte.Eiweiß}</p>
                <p className="col-s-6 br">Salz</p>
                <p className="col-s-6">{naehrwerte.Salz}</p>
            </div>
          </div>
        </div>
      )
    } else {
      if(window.innerWidth < 768) {
        return (
          <div className="container modallist-container ">
            <div className="padding-modal-produkt">
              <div className="closeBtn"></div>
              <div className="container-big nopl nopr nopt">
                <SubTitle>Allergene</SubTitle>
              </div>
              <div className="row">
                  <p className="col-s-8 br bb bold">Allergen</p>
                  <p className="col-s-4 bb ">Milch</p>

                  <p className="col-s-8 br bold">Zutat</p>
                  <p className="col-s-4">{isAllergene}</p>

                  <p className="col-s-8 br bold">Im Werk</p>
                  <p className="col-s-4">{isAllergene}</p>

                  <p className="col-s-8 br bold">Kreuzkontamination</p>
                  <p className="col-s-4">{isAllergene}</p>

              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className="container modallist-container ">
            <div className="padding-modal-produkt">
              <div className="closeBtn"></div>
              <div className="container-big nopl nopr nopt">
                <SubTitle>Allergene</SubTitle>
              </div>
              <div className="row">
                  <p className="col-s-3 br bb bold">Allergen</p>
                  <p className="col-s-3 br bb bold">Zutat</p>
                  <p className="col-s-3 br bb bold">Im Werk</p>
                  <p className="col-s-3 bb bold">Kreuzkontamination</p>

                  <p className="col-s-3 br">Gluten</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>

                  <p className="col-s-3 br">Krebstiere</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>

                  <p className="col-s-3 br">Eier</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>

                  <p className="col-s-3 br">Fisch</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>

                  <p className="col-s-3 br">Erdnüsse</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>

                  <p className="col-s-3 br">Sojabohnen</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>

                  <p className="col-s-3 br">Milch</p>
                  <p className="col-s-3 br">{isAllergene}</p>
                  <p className="col-s-3 br">{isAllergene}</p>
                  <p className="col-s-3">{isAllergene}</p>

                  <p className="col-s-3 br">Schalenfrüchte</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>

                  <p className="col-s-3 br">Sellerie</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>

                  <p className="col-s-3 br">Senf</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>

                  <p className="col-s-3 br">Schwefeldioxid/Sulfide</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>

                  <p className="col-s-3 br">Lupine</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>

                  <p className="col-s-3 br">Weichtiere</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3 br">Nein</p>
                  <p className="col-s-3">Nein</p>
              </div>
            </div>
          </div>
        )
      }
    }
  }
  return (
    <div className={"modalboxContent flexbox justify-center flex-align-center pointer " + props.animation} onClick={props.close}>
      <div>
        {renderHelper()}
      </div>
    </div>
  )
}
