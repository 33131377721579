import React, { Component } from 'react';

export class Header extends Component {
  state = {
    width: window.innerWidth,
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    window.addEventListener("scroll", this.scroll);
    this.setState({
      image: this.state.width > 1024 ? this.props.imageB : this.state.width > 414 ? this.props.imageM : this.props.imageS,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("scroll", this.scroll);
  }

  resize = () => {
    const useImage = this.state.width > 1024 ? this.props.imageB : this.state.width > 414 ? this.props.imageM : this.props.imageS;
    this.setState({
      width: window.innerWidth,
      image: useImage,
    })
  }

  // scroll = () => {
  //   const header = this.refs.header;
  //   const content = this.refs.header;
  //   const headerPos = header.getBoundingClientRect();
  //   const contentPos = header.getBoundingClientRect();

  //   const myOpacityBg = 0.2 + headerPos.top / 700;
  //   const myOpacityC = 1 + contentPos.top / 700;

  //   this.setState({
  //     opacityBg: myOpacityBg,
  //     opacityC: myOpacityC,
  //   })

  //   header.style.opacity = this.state.opacityBg;
  //   content.style.opacity = this.state.opacityC;
  // }

  scrollDown = () => {
    window.scroll({left: 0, top: window.innerHeight - 50, behavior: 'smooth'})
  }

  render() {
    const { props } = this;
    return (
      <div ref="header" className="xy-hidden" style={{height: 'auto'}}>
        <img alt="" className="header-img" src={this.state.image} />
        {/* <div ref="content">
          <Fade  delay={1200}>
            <div className="pt container-big full-visible">
              <div className="row pt col-s-12">
                <div className="flexbox col-s-12 container-middle-small justify-center flex-align-center">
                  <img alt="Sonnenalm Logo" src={props.logo} />
                </div>
                <div className="col-lg-12">
                  <HeadlineContainer>
                    <hr className="center" />
                    <PageTitle className="align-center">{props.title}</PageTitle>
                    <hr className="center" />
                  </HeadlineContainer>
                </div>
              </div>
            </div>
            <div className="container-big">
              <div className="flexbox justify-center flex-align-center pointer" onClick={this.scrollDown}>
                <DownArrow className="bounce downArrow " />
              </div>
              <p className="small col-s-12 align-center primary-dark">scroll down</p>
            </div>
          </Fade>
        </div> */}
      </div>
    )
  }

};
