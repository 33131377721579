import React, { Component } from 'react';

import { ROOT_URL_ORIGIN } from '../../../../config';

import { Download } from '../../../reusable/styled/download';

class Zertifikat extends Component {


  download = (data) => {
    window.open(ROOT_URL_ORIGIN + data.value.zertifikat.path);
  }

  renderHelper = () => {
    if(!this.props.loaded) {return null}

    return this.props.data.certificate.map((data, i) => {
      return (
        <div key={i} className="col-s-6 col-md-3 col-lg-2">
          <div className="container-big nop">
            <Download title={data.value.title} download={() => this.download(data)}/>
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      this.renderHelper()
    );
  }
}

export default Zertifikat;
