import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetch } from "../../../redux/actions";

import {
  PageContainer,
  HeadlineContainer
} from "../../reusable/styled/container";
import {
  Title,
  Paragraph,
  SubTitle,
  SmallParagraph
} from "../../reusable/styled/fonts";
import { Header } from "../../reusable/styled/header";
import Logo from "../../../media/logos/Logo.svg";
import { PageLink } from "../../reusable/styled/pageLink";
import { PrimaryButton, SecondaryButton } from "../../reusable/styled/buttons";

import SliderContainer from "../../reusable/styled/slider";

import Milcherlebniswelt from "../../../media/images/Milcherlebniswelt5_Button.jpg";
import SchulmilchKinder from "../../../media/images/SchulmilchKinder.jpg";
import UnsereBauern from "../../../media/images/bauern.jpg";
import ProdukteButton from "../../../media/images/ProdukteButton.jpg";

import SonnenIcon from "../../../media/images/SonnenIcon.png";

import BigImg from "../../../media/header/KuhBauer/header_home_big.jpg";
import MiddleImg from "../../../media/header/KuhBauer/header_home_medium.jpg";
import SmallImg from "../../../media/header/KuhBauer/header_home_small.jpg";

import BigImg2 from "../../../media/header/Lehrling/header_home_big.jpg";
import MiddleImg2 from "../../../media/header/Lehrling/header_home_medium.jpg";
import SmallImg2 from "../../../media/header/Lehrling/header_home_small.jpg";

import BigImg3 from "../../../media/header/Heu/header_home_big.jpg";
import MiddleImg3 from "../../../media/header/Heu/header_home_medium.jpg";
import SmallImg3 from "../../../media/header/Heu/header_home_small.jpg";

import Fade from "react-reveal/Fade";

import { ROOT_URL } from "../../../config";

class Home extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      this.props.fetch("fetch_home", `${ROOT_URL}/api/singletons/get/Homepage?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Willkommen bei der Sonnenalm | Home - Sonnenalm</title>
          <meta
            name="description"
            content="Willkommen bei der Sonnenalm hier finden Sie alle Informationen um Milchprodukte, Ziegenmilchprodukte unsere Schulmilch und vieles mehr"
          />
          <link rel="canonical" href="http://www.milcherlebniswelt.at" />
        </Helmet>

        <SliderContainer
          className="xy-hidden"
          imagesB={[BigImg, BigImg2, BigImg3]}
          imagesM={[MiddleImg, MiddleImg2, MiddleImg3]}
          imagesS={[SmallImg, SmallImg2, SmallImg3]}
        />

        <div className="container-big" style={{ paddingTop: "75px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.home.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.home.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{ __html: this.props.home.text }}
              ></div>
            )}
          </div>
          <div className="row justify-center pt-10">
            <img
              style={{ width: "100px", height: "100px" }}
              alt="sonnen icon"
              className="sonne"
              src={SonnenIcon}
            />
          </div>
        </div>

        <div className="container-big">
          <div
            className="container-middle-big"
            dangerouslySetInnerHTML={{
              __html: this.props.home.videoDescription
            }}
          ></div>
          <div className="row justify-center">
            <div style={{ maxWidth: "900px", width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  paddingTop: "56.25%",
                  position: "relative"
                }}
              >
                <div
                  className="yVideo"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    padding: "15px"
                  }}
                  dangerouslySetInnerHTML={{ __html: this.props.home.video }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-big" style={{ paddingTop: "45px" }}>
          <div className="row justify-center">
            {/* <div className="col-md-4 col-s-6 nop">
              <NavLink to="" onClick={() => window.scroll({ top: 0 })}>
                <PageLink to="/produkte" backgroundImage={ProdukteButton}>
              <p className="pageLinkText align-center">Produkte</p>
                </PageLink>
              </NavLink>
            </div> */}
            <div className="col-md-4 col-s-6 nop" onClick={() => window.scroll({ top: 0 })}>
              <NavLink to="" onClick={() => window.scroll({ top: 0 })}>
              </NavLink>
              <PageLink to="/produkte" backgroundImage={ProdukteButton}>
                <p className="pageLinkText align-center">Produkte</p>
              </PageLink>
            </div>
            <div className="col-md-4 col-s-6 nop" onClick={() => window.scroll({ top: 0 })}>
              <NavLink to="" onClick={() => window.scroll({ top: 0 })}>
              </NavLink>
              <PageLink
                to="/milcherlebniswelt"
                backgroundImage={Milcherlebniswelt}
              >
                <p className="pageLinkText align-center">Milcherlebniswelt</p>
              </PageLink>
            </div>
            <div className="col-md-4 col-s-6 nop" onClick={() => window.scroll({ top: 0 })}>
              <NavLink to="" onClick={() => window.scroll({ top: 0 })}>
              </NavLink>
              <PageLink
                to="ueber-uns/unsere-bauern"
                backgroundImage={UnsereBauern}
              >
                <p className="pageLinkText align-center">Unsere Bauern</p>
              </PageLink>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}

const mapStateToProps = data => {
  return {
    loaded: data.loaded.home,
    home: data.home
  };
};

export default connect(mapStateToProps, { fetch })(Home);
