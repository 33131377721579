import React, { Component } from "react";
import { ROOT_URL, ROOT_URL_ORIGIN } from "../../../config";
import { fetch } from "../../../redux/actions";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import {
  PageContainer
} from "../../reusable/styled/container";
import {
  Title,
  Paragraph,
  SubTitle,
  SmallParagraph
} from "../../reusable/styled/fonts";
import { Header } from "../../reusable/styled/header";
import Logo from "../../../media/logos/Logo.svg";

import BigImg from "../../../media/header/header_ÜberUns_big.jpg";
import MiddleImg from "../../../media/header/header_ÜberUns_medium.jpg";
import SmallImg from "../../../media/header/header_ÜberUns_small.jpg";

import EULeiste from "../../../media/images/logoleisteBMNT.jpg";

import Zertifikat from "./certificates";

class About extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      this.props.fetch(
        "fetch_about",
        `${ROOT_URL}/api/singletons/get/about?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Alles über die Sonnenalm | Über uns - Sonnenalm</title>
          <meta
            name="description"
            content="Bäuerlicher Milchhof Sonnenalm - eine regionale Erfolgsgeschichte"
          />
          <link
            rel="canonical"
            href="http://www.milcherlebniswelt.at/ueber-uns"
          />
        </Helmet>

        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Über uns"
          ></Header>
        </div>

        {/* Background Image von unsere Bauern */}
        <div className="container-big" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.about.title}
          </h1>
          <hr className="center" />
        </div>

        <div className="container-big nopt">
          <div className="row pt-half">
            <div className="col-s-12">
              <Title className="align-center">
                {this.props.loaded && this.props.about.philosophyTitle}
              </Title>
              <div className="align-center" style={{ paddingTop: "30px" }}>
                {this.props.loaded && (
                  <div
                    className="container nop align-center"
                    dangerouslySetInnerHTML={{
                      __html: this.props.about.philosophyText
                    }}
                  ></div>
                )}
              </div>
            </div>

            <div className="container-big row justify-center">
              <div>
                <div style={{ width: "100%" }} className="row justify-center stretchedImage">
                  <img
                    style={{ maxWidth: "400px", height: "auto" }}
                    alt="Portrait Hannes Zechner"
                    className="r"
                    src={
                        this.props.loaded &&
                      `${ROOT_URL_ORIGIN}${this.props.about.person.path}`
                    }
                  />
                </div>
                <div className="col-lg-2"></div>
                <div
                  style={{ width: "100%", paddingTop: "60px" }}
                  className="row justify-center"
                >
                  <SubTitle style={{ width: "100%" }} className="align-center bold">
                    {this.props.about.name}
                  </SubTitle>
                </div>
                <div style={{ width: "100%" }} className="row justify-center">
                  <Paragraph className="bold primary-dark">
                    {this.props.about.position}
                  </Paragraph>
                </div>
                <div
                  style={{ width: "100%", padding: "25px" }}
                  className="row justify-center"
                >
                  <h3 className="middle set-font-zitat">
                    {this.props.about.zitat}
                  </h3>
                </div>
                <SmallParagraph
                  className="align-center"
                  style={{ opacity: "0.5", padding: "25px" }}
                >
                  {this.props.about.addon}
                </SmallParagraph>
                <div className="col-s-12 pt-half">
                  {this.props.loaded && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.about.textbelow
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* 3 Bilder mit Text */}
          <div>
            <div className="row container-big nopl nopr nopb pt-half justify-center">
              <div className="col-md-3">
                <div
                  style={{
                      backgroundColor: "white",
                      boxShadow: "0 0 99px rgba(0,0,0,0.2)",
                      borderRadius: "16px",
                      height: "100%"
                  }}
                >
                  <div>
                    <img
                      alt="Bröseltopfenfertiger"
                      className="r"
                      src={
                          this.props.loaded &&
                          `${ROOT_URL_ORIGIN}${this.props.about.productionImage1.path}`
                      }
                    />
                  </div>

                  <div className="col-s-12">
                    {this.props.loaded && (
                      <div
                        dangerouslySetInnerHTML={{
                            __html: this.props.about.productionText1
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>



              <div className="col-md-3">
                <div
                  style={{
                      backgroundColor: "white",
                      boxShadow: "0 0 99px rgba(0,0,0,0.2)",
                      borderRadius: "16px",
                      height: "100%"
                  }}
                >
                  <div>
                    <img
                      alt="Bröseltopfenfertiger"
                      className="r"
                      src={
                          this.props.loaded &&
                          `${ROOT_URL_ORIGIN}${this.props.about.productionImage2.path}`
                      }
                    />
                  </div>

                  <div className="col-s-12">
                    {this.props.loaded && (
                      <div
                        dangerouslySetInnerHTML={{
                            __html: this.props.about.productionText2
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>




              <div className="col-md-3" style={{ height: "100%" }}>
                <div
                  style={{
                      backgroundColor: "white",
                      boxShadow: "0 0 99px rgba(0,0,0,0.2)",
                      borderRadius: "16px",
                      height: "100%"
                  }}
                >
                  <div>
                    <img
                      alt="Bröseltopfenfertiger"
                      className="r"
                      src={
                          this.props.loaded &&
                          `${ROOT_URL_ORIGIN}${this.props.about.productionImage3.path}`
                      }
                    />
                  </div>

                  <div className="col-s-12">
                    {this.props.loaded && (
                      <div
                        dangerouslySetInnerHTML={{
                            __html: this.props.about.productionText3
                        }}
                      ></div>
                    )}
                  </div>
                </div>




              </div>
            </div>
          </div>
        </div>
        <div className="container-big">
          <div className="row">
            <div className="col-s-12">
              <Title className="align-center">
                <span className="bold">SO </span>kontrolliert! <br />
                Unsere Zertifizierungen
              </Title>
            </div>
          </div>
          <div className="row justify-center">
            <Zertifikat data={this.props.about} loaded={this.props.loaded} />
          </div>
          <div className="row container-big pt-half justify-center">
            <div className="col-md-6">
              <a
                href="https://www.bmnt.gv.at/ministerium/publizitaets/ELER-foerderung/eler.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={EULeiste} alt=""/>
              </a>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}

const mapStateToProps = data => {
  return {
    loaded: data.loaded.about,
    about: data.about
  };
};

export default connect(mapStateToProps, { fetch })(About);
