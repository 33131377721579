import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetch } from '../../../../redux/actions';

import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Title, SubTitle } from '../../../reusable/styled/fonts';
import { PageContainer, HeadlineContainer } from '../../../reusable/styled/container';
import { PageLink } from '../../../reusable/styled/pageLink';
import { Header } from '../../../reusable/styled/header';
import Logo from '../../../../media/logos/Logo.svg';

import Erlebniswelt from '../../../../media/images/milcherlebniswelt5.jpg';
import Kaeseschule from '../../../../media/images/kaeseschule_.jpg';
import Hofladen from '../../../../media/images/hofladen_klein.jpg';

import BigImg from '../../../../media/header/header_erlebniswelt_big.jpg';
import MiddleImg from '../../../../media/header/header_erlebniswelt_medium.jpg';
import SmallImg from '../../../../media/header/header_erlebniswelt_small.jpg';

import Kuhhotel from '../../../../media/images/Kuhhotel.jpg';
import Milcherlebnis from '../../../../media/images/Milcherlebniswelt5_Button.jpg';
import MilchBesichtigung from '../../../../media/images/milcherlebniswelt5.jpg';

import Fade from 'react-reveal/Fade';

import { ROOT_URL, ROOT_URL_ORIGIN } from '../../../../config';


class Milcherlebniswelt extends Component {
  componentDidMount() {
    if(!this.props.loaded || !this.props.loadedOpentimes) {
      this.props.fetch('fetch_milkworld', `${ROOT_URL}/api/singletons/get/milkworld?token=b0e71c309917ffc064b1a14ea6a6cd`)
      this.props.fetch('fetch_opentimes_settings', `${ROOT_URL}/api/singletons/get/opentimes?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  renderPriceCardList = (data) => {
    return data.map((data, i) => {
      return (
        <>
          <p className="col-s-8 br ">{data.value.person}</p>
          <p className="col-s-4 ">{data.value.price}</p>
        </>
      )
    })
  }

  renderPriceCards = () => {
    if(this.props.loaded) {
      return this.props.milkworld.pricecard.map((data, i) => {
        return (
          <div className="col-lg-4 col-ml-5 col-md-6">
            <div className="card shadow-large modalboxContent-normal">
              <div className="modallist-container nop nos">
                <img
                  style={{ width: "100%", borderRadius: "16px" }}
                  alt="erlebniswelt bild"
                  src={`${ROOT_URL_ORIGIN}/cockpit-sonnenalm/storage/uploads/${data.value.image.path}`}
                />
                <div
                  className="padding-modal-produkt"
                  style={{ padding: "15px" }}
                >
                  <SubTitle className="black primary-dark container">
                    {data.value.title}
                  </SubTitle>
                  <p>{data.value.description}</p>
                  <p
                    style={{ paddingLeft: "15px", top: '30px' }}
                    className="bottom"
                  >
                    {data.value.dauer}

                  </p>
                  <div className="row pt-half">
                    <p className="col-s-8 br bb bold">Personen</p>
                    <p className="col-s-4 bb bold">Kosten €</p>

                    {this.renderPriceCardList(data.value.repeat)}
                  </div>
                </div>

              </div>
            </div>
          </div>
        );
      })
    }
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Wie wird die Milch verarbeitet? | Milcherlebniswelt - Sonnenalm
          </title>
          <meta
            name="description"
            content="Einblicke in die Milchproduktion. nsere gläserne Molkerei bietet Ihnen einzigartige Einblicke in die traditionelle Milchverarbeitung und hinter die Kulissen des Bäuerlichen Milchhofes Sonnenalm."
          />
          <link
            rel="canonical"
            href="http://www.milcherlebniswelt.at/milcherlebniswelt"
          />
        </Helmet>

        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Milcherlebniswelt"
          ></Header>
        </div>

        <div className="container-big" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.milkworld.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.milkworld.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.milkworld.description
                }}
              ></div>
            )}
          </div>
        </div>

        <div className="divider-2">
          <div className="container-big nopb" style={{paddingTop: '50px'}}>
            <div className="row">
              <div className="col-s-12 nop">
                <div className="row ">
                  <Fade>{this.renderPriceCards()}</Fade>
                  <Fade>
                    <div className="col-s-12">
                      {this.props.loaded && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.props.milkworld.text
                          }}
                        ></div>
                      )}
                    </div>
                  </Fade>
                  <div className="container-big pt">
                    <div className="row justify-center">
                      <div className="col-md-4 col-s-6 nop">
                        <NavLink onClick={() => window.scroll({ top: 0 })}>
                          <PageLink
                            to="/erlebniswelt"
                            backgroundImage={Erlebniswelt}
                          >
                            <p className="pageLinkText align-center">
                              Erlebniswelt
                            </p>
                          </PageLink>
                        </NavLink>
                      </div>
                      <div className="col-md-4 col-s-6 nop">
                        <NavLink onClick={() => window.scroll({ top: 0 })}>
                          <PageLink
                            to="/kaeseschule"
                            backgroundImage={Kaeseschule}
                          >
                            <p className="pageLinkText align-center">
                              Käseschule
                            </p>
                          </PageLink>
                        </NavLink>
                      </div>
                      <div className="col-md-4 col-s-6 nop">
                        <NavLink onClick={() => window.scroll({ top: 0 })}>
                          <PageLink
                            to="/ab-hof-verkauf"
                            backgroundImage={Hofladen}
                          >
                            <p className="pageLinkText align-center">
                              Hofladen
                            </p>
                          </PageLink>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}


const mapStateToProps = (data) => {
  return {
    milkworld: data.milkworld,
    loaded: data.loaded.milkworld,
    opentimes: data.opentimesSettings,
    loadedOpentimes: data.loaded.opentimes
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(Milcherlebniswelt)
