import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ROOT_URL } from '../../../../../config';
import { fetch } from '../../../../../redux/actions';

import { Download } from '../../../../reusable/styled/download';

class DownloadsRender extends Component {
  componentDidMount() {
    if(!this.props.loaded) {
      this.props.fetch('fetch_schulmilch_downloads', `${ROOT_URL}/api/collections/get/schulmilchDownloads?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  download = (data) => {
    window.open(ROOT_URL + '/storage/uploads' + data.pdf.path);
  }

  renderHelper = () => {
    if(!this.props.loaded) {return null}

    return this.props.downloads.map((data, i) => {
      return (
        <div key={i} className="col-s-6 col-lg-4">
          <div className="container-big nop">
            <Download title={data.name} download={() => this.download(data)}/>
          </div>
        </div>
      )
    })
  }

  render() {
    return (
      this.renderHelper()
    );
  }
}

const mapStateToProps = (data) => {
  return {
    downloads: data.schulmilchDownloads,
    loaded: data.loaded.schulmilchDownloads,
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(DownloadsRender)
