import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import MobileNav from "./mobile-nav";
import DesktopNav from "./desktop-nav";

class Navigation extends Component {
  state = {
    width: window.innerWidth,
    location: window.location.href
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  render() {
    if (this.state.width > 1023) {
      return (
        <>
          <DesktopNav width={this.state.width} />
        </>
      );
    } else {
      return (
        <>
          <MobileNav />
        </>
      );
    }
  }
}

export const NavItem = props => (
  <div className="nav-item-container flexbox flex-align-center">
    <p onClick={() => window.scroll({ top: 0 })} className="navlink smallN">
      <NavLink activeClassName="avtiveNavlink" exact to={props.to}>
        {props.content}
      </NavLink>
    </p>
  </div>
);

export const NavItemDropDown = props => (
  <div className="nav-item-container flexbox flex-align-center">
    <div className="dropDown-container">
      <p onClick={() => window.scroll({ top: 0 })} className="navlink smallN">
        <NavLink activeClassName="avtiveNavlink" to={props.to}>
          {props.content}
        </NavLink>
      </p>
      {/* every child is a NavItem */}
      <div className="dropDown-item-container">{props.children}</div>
    </div>
  </div>
);

export default Navigation;
