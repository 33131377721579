import { FETCH_COWMILK } from "../../actions/type";

const initState = {};

export default function(state = initState, action) {
  switch (action.type) {
    case FETCH_COWMILK:
      return action.data;
    default:
      return state;
  }
}
