import React from 'react';

import { PageContainer } from '../reusable/styled/container';
import { Title } from '../reusable/styled/fonts';

import ErrorTopfen from '../../media/error_topfen.svg';

const ERROR404 = (props) => (
  <PageContainer>
    <div className="fh-v">
      <div className="container-full fh-v">
        <div className="row fh pt justify-center flex-align-center">
          <div className="col-md-5">
            <img alt="" src={ErrorTopfen} />
          </div>
          <div className="col-md-7">
            <Title>Diese Seite existiert nicht</Title>
            <h1 className="set-font-sp errorSize">ERROR 404</h1>
            <p className="container nopl nopr">
              Zu der Url <span className="primary-light">{window.location.href}</span> konnte keine existierende Seite gefunden werden.
            </p>
          </div>
        </div>
      </div>
    </div>
  </PageContainer>
);

export default ERROR404;
