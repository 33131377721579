import React from 'react';

export const PrimaryButton = (props) => (
  <div className={"ssp " + props.className}>
    <button className="btn" onClick={props.onClick}>{props.content}</button>
  </div>
);

export const SecondaryButton = (props) => (
  <div className={"ssp " + props.className}>
    <button className="btn-secondary" onClick={props.onClick}>{props.content}</button>
  </div>
);

export const TertiaryButton = (props) => (
  <div className={"ssp " + props.className}>
    <button className="btn-tertiary" onClick={props.onClick}>{props.content}</button>
  </div>
);

export const QuatroButton = (props) => (
  <div className={"ssp " + props.className}>
    <button className="btn-quatro" onClick={props.onClick}>{props.content}</button>
  </div>
);
