import React, { Component } from "react";
import { connect } from "react-redux";
import { fetch } from "../../../redux/actions";
import Helmet from "react-helmet";

import Fade from 'react-reveal/Fade';

import {
  PageContainer
} from "../../reusable/styled/container";
import { Paragraph } from "../../reusable/styled/fonts";
import { Header } from "../../reusable/styled/header";
import Logo from "../../../media/logos/Logo.svg";
import AnsprechpartnerRender from "./ansprechpartner/ansprechpartner-render";

import BigImg from "../../../media/header/header_Produktion_big.jpg";
import MiddleImg from "../../../media/header/header_Produktion_medium.jpg";
import SmallImg from "../../../media/header/header_Produktion_small.jpg";

import { Phone, Mail, Fax } from "../../../media/icons/file";

import { ROOT_URL } from "../../../config";

class Contact extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      this.props.fetch(
        "fetch_contact_settings",
        `${ROOT_URL}/api/singletons/get/contactsettings?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
    if (!this.props.loaded2) {
      this.props.fetch(
        "fetch_contacts",
        `${ROOT_URL}/api/singletons/get/contacts?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
  }

  render() {
    return (
      <PageContainer className="xy-hidden">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Kontaktieren Sie uns! | Kontakt - Sonnenalm</title>
          <meta
            name="description"
            content="Sie haben Fragen? Kontaktieren Sie unsere Ansprechpartner. Wir freuen uns auf Ihren anruf"
          />
          <link
            rel="canonical"
            href="http://www.milcherlebniswelt.at/kontakt"
          />
        </Helmet>

        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Kontakt"
          ></Header>
        </div>

        <div className="container-big" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.contacts.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.contacts.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.contacts.text
                }}
              ></div>
            )}
          </div>
        </div>

        <div className="container-middle-small">
          <div className="col-s-12">
            <Fade>
              <p className="align-center bold primary-dark">
                <Paragraph className="header-max-width-p">
                  <span className="bold">Sonnenalm Bäuerlicher Milchhof e.Gen. </span><br />
                  Milchstraße 1 <br />
                  9373 Klein St. Paul <br />
                </Paragraph>
              </p>
              <div className="container nopl nopr nopb ">
                <div className="row flex-align-center container nopl nopr nopt justify-center">
                  <Phone className="icon IE11ICONFIXP" />
                  <p className="align-center">
                    {this.props.loaded && this.props.contact.tel}
                  </p>
                </div>
                <div className="row flex-align-center container nopl nopr nopt justify-center">
                  <Fax className="icon IE11ICONFIXF" />
                  <p className="align-center">
                    {this.props.loaded && this.props.contact.fax}
                  </p>
                </div>
                <div className="row flex-align-center container nopl nopr nopt justify-center">
                  <Mail className="icon IE11ICONFIXM" />
                  <p className="align-center">
                    {this.props.loaded && this.props.contact.mail}
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>

        <div className="container-big">
          <div className="row pt">
            <div className="pt-h container-big nopl nopr nopb">
              <AnsprechpartnerRender />
            </div>

            <div className="col-s-12 pt">
              <div className=" nom nopl">

                <div className="row nom nopl nopr">
                  <iframe
                    title="google maps"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6491.421396446434!2d14.54081077619087!3d46.83765613870112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47703e1e807fb4bb%3A0xb67a094ffabedb5a!2sSonnenalm%20b%C3%A4uerliche%20Vermarktung%20regGenmbH!5e0!3m2!1sde!2sat!4v1568186942258!5m2!1sde!2sat"
                    className="iframe"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}

const mapStateToProps = data => {
  return {
    contact: data.contactSettings,
    contacts: data.contacts,
    loaded: data.loaded.contact,
    loaded2: data.loaded.contacts
  };
};

export default connect(mapStateToProps, { fetch })(Contact);
