import { FETCH_ABOUT } from '../../actions/type';

const initState = {}

export default function(state = initState, action) {
  switch(action.type) {
    case FETCH_ABOUT:
    return action.data
    default:
      return state;
  }
}
