import React from "react";

import { NavLink } from "react-router-dom";

export const PageLink = props => (
         <NavLink to={props.to}>
           <div className="col-s-12">
             <div className={"pageLink"}>
               <div className="innerContainer xy-hidden">
                 <img
                   alt=""
                   className="pageLink-img"
                   src={props.backgroundImage}
                 />
                 <div className="row fh justify-center flex-align-center">
                   <div className="col-s-12 nopb">
                     <hr className="center pageLinkHr" />
                   </div>
                   <div className="col-s-12 nop">{props.children}</div>
                   <div className="col-s-12 nopt">
                     <hr className="center pageLinkHr" />
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </NavLink>
       );
