import { FETCH_KUHMILCHPRODUKTE} from '../actions/type';

const initState = [
  {
    amount: "",
    image: "",
    naehrwerte: {},
    allergene: false,
    price: "",
    produktname: "",
    subtitle: "",
    tag: []
  },
  {
    amount: "",
    image: "",
    naehrwerte: {},
    allergene: false,
    price: "",
    produktname: "",
    subtitle: "",
    tag: []
  },
  {
    amount: "",
    image: "",
    naehrwerte: {},
    allergene: false,
    price: "",
    produktname: "",
    subtitle: "",
    tag: []
  },
  {
    amount: "",
    image: "",
    naehrwerte: {},
    allergene: false,
    price: "",
    produktname: "",
    subtitle: "",
    tag: []
  },
  {
    amount: "",
    image: "",
    naehrwerte: {},
    allergene: false,
    price: "",
    produktname: "",
    subtitle: "",
    tag: []
  },
  {
    amount: "",
    image: "",
    naehrwerte: {},
    allergene: false,
    price: "",
    produktname: "",
    subtitle: "",
    tag: []
  },
  {
    amount: "",
    image: "",
    naehrwerte: {},
    allergene: false,
    price: "",
    produktname: "",
    subtitle: "",
    tag: []
  },
  {
    amount: "",
    image: "",
    naehrwerte: {},
    allergene: false,
    price: "",
    produktname: "",
    subtitle: "",
    tag: []
  }
];

export default function(state = initState, action) {
  switch(action.type) {
    case FETCH_KUHMILCHPRODUKTE:
      return action.data.entries.map(data => {
        return {
          amount: data.amount,
          image: data.image,
          naehrwerte:
            data.naehrwerte[0] !== undefined
              ? data.naehrwerte
                  .map(data => {
                    return { [data.field.label]: data.value.display };
                  })
                  .reduce((acc, cur) => ({ ...acc, ...cur }))
              : {},
          price: data.price,
          produktname: data.produktname,
          subtitle: data.subtitle,
          tag: data.tag,
          allergene: data.allergene,
          category: data.category
        };
      });
    default:
      return state;
  }
}
