import React, { Component } from 'react';

import { delayUnmounting } from './hoc_delayUnmount';

const Box = (props) => (
  <div>
    {props.children}
  </div>
);

// create a delay component
const DelayedComponent = delayUnmounting(Box)

class Modalbox extends Component {
  render() {
    return (
      <>
        <DelayedComponent delayTime={this.props.delayTime} isMounted={this.props.isMounted}>
        {/* pass the modalbox content down with the prop [modalContent] */}
          {this.props.modalContent}
        </DelayedComponent>
        {this.props.children}
      </>
    );
  }
}

export default Modalbox;
