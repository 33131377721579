import React from 'react';

import { PageContainer, HeadlineContainer } from '../reusable/styled/container';
import { Title, Paragraph, SubTitle } from '../reusable/styled/fonts';

const Datenschutz = () => (
  <PageContainer>
    <div className="pt container-big full-visible">
      <div className="row pt">
        <div className="col-lg-12">
          <HeadlineContainer>
            <Title>Datenschutz</Title>
          </HeadlineContainer>
          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className=" bold">Datenschutz</SubTitle>
            </div>
            <Paragraph>Wir haben diese Datenschutzerklärung (Fassung 03.09.2019-211101137) verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 und dem <a className="link-light" href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=211101186">Datenschutzgesetz (DSG)</a> zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.<br />
            Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen. Wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.</Paragraph>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className=" bold">Rechte laut Datenschutzgrundverordnung</SubTitle>
            </div>
            <Paragraph>Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen <a className="link-light" href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=211101186">Datenschutzgesetzes (DSG)</a> grundsätzlich die folgende Rechte zu:</Paragraph>
            <ul className="list">
              <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
              <li>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
              <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
              <li>Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)</li>
              <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
              <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
              <li>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</li>
            </ul>
            <Paragraph>
              Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren, welche in Österreich die Datenschutzbehörde ist, deren Webseite Sie unter <a className="link-light" href="https://www.dsb.gv.at/">https://www.dsb.gv.at/</a> finden.
            </Paragraph>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className=" bold">TLS-Verschlüsselung mit https</SubTitle>
            </div>
            <Paragraph>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung <a className="link-light" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=211101188">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.</Paragraph>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className=" bold">Google Fonts Lokal Datenschutzerklärung</SubTitle>
            </div>
            <Paragraph>Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite. Wir haben die Google-Schriftarten lokal, d.h. auf unserem Webserver – nicht auf den Servern von Google – eingebunden. Dadurch gibt es keine Verbindung zu Server von Google und somit auch keine Datenübertragung bzw. Speicherung.</Paragraph>
            <div className="container-big">
              <Paragraph className=" bold ">Was sind Google Fonts?</Paragraph>
              <Paragraph>Google Fonts (früher Google Web Fonts) ist ein interaktives Verzeichnis mit mehr als 800 Schriftarten, die die <a className="link-light" href="https://de.wikipedia.org/wiki/Google_LLC?tid=211101188">Google LLC</a> zur freien Verwendung bereitstellt. Mit Google Fonts könnte man die Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich jede Informationsübertragung zum Google-Server zu unterbinden, haben wir die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden keine Daten an Google Fonts weiter. <br />
                Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a className="link-light" href="https://developers.google.com/fonts/faq?tid=211101137">https://developers.google.com/fonts/faq?tid=211101137</a>.</Paragraph>
            </div>
          </div>


          <div className="container-big">
            <div className="container-big nopl">
              <SubTitle className=" bold ">Google Fonts Datenschutzerklärung</SubTitle>
            </div>
            <Paragraph>Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite. <br />
              Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-
            Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</Paragraph>
            <div className="container-big">
              <Paragraph className=" bold ">Warum verwenden wir Google Fonts auf unserer Webseite?</Paragraph>
              <Paragraph>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sogenannte sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser ( Google Chrome, Mozilla Firefox, Apple Safari, Opera)  und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph className=" bold ">Welche Daten werden von Google gespeichert?</Paragraph>
              <Paragraph>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um die Erfassung, Speicherung und Verwendung von Endnutzerdaten auf das zu reduzieren, was für eine effiziente Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich. <br />
                Google Fonts speichert CSS- und Font-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google die Beliebtheit der Schriften feststellen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. BigQuery ist ein Webservice von Google für Unternehmen, die große Datenmengen bewegen und analysieren wollen. <br />
                Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie IP-Adresse, Spracheinstellungen, Bildschirmauflösung des Browsers, Version des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert. <br /></Paragraph>
            </div>
            <div className="container-big">
              <Paragraph className=" bold ">Wie lange und wo werden die Daten gespeichert?</Paragraph>
              <Paragraph>Anfragen für CSS-Assets speichert Google einen Tag lang auf Ihren Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann. <br />
              Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.</Paragraph>
            </div>
            <div className="container-big">
              <Paragraph className=" bold ">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</Paragraph>
              <Paragraph>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf <a className="link-light" href="https://support.google.com/?hl=de&tid=211101137">https://support.google.com/?hl=de&tid=211101137</a> kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen. <br />
                Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a className="link-light" href="https://developers.google.com/fonts/faq?tid=211101137">https://developers.google.com/fonts/faq?tid=211101137</a>. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig (beinahe unmöglich), von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen. <br />
                Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf <a className="link-light" href="https://www.google.com/intl/de/policies/privacy/">https://www.google.com/intl/de/policies/privacy/</a> nachlesen. <br /> <br />
              </Paragraph>
            </div>
            <Paragraph>Quelle: Erstellt mit dem <a className="link-light" href="https://www.firmenwebseiten.at/datenschutz-generator/">Datenschutz Generator von firmenwebseiten.at</a> in Kooperation mit <a className="link-light" href="https://www.justmed.de/">justmed.de</a></Paragraph>
          </div>
        </div>
      </div>
    </div>
  </PageContainer>
);


export default Datenschutz;
