import React from 'react';

export const PageContainer = (props) => (
  <div className={"container-full " + props.className}>
    {props.children}
  </div>
);

export const Section = (props) => (
  <div className={"container-big section"} style={props.style}>
    {props.children}
  </div>
);

export const HeadlineContainer = (props) => (
  <div className="headline-padding">
    {props.children}
  </div>
);

export const ImageSection = (props) => (
  <section className={"bg-image-schulmilchB mt-mobile nop " + props.className}>
    <div className="header-o-layer fh"></div>
    <img alt="" className="dividerTop" src={props.topImage} />
    {props.children}
    <img alt="" className="dividerBottom" src={props.bottomImage} />
  </section>
);
