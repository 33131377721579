import React, { Component } from 'react';

import { Paragraph, SubTitle } from '../../../reusable/styled/fonts';

import {ModalboxContainer} from './modalbox';

import { ROOT_URL_ORIGIN } from '../../../../config';


class MilchBauern extends Component {
  state = {
    isMountedB: false,
    animation: 'fadeOut'
  }

  toggle = () => {
    this.setState({isMountedB: !this.state.isMountedB})
    this.setState({animation: this.state.animation === 'fadeIn' ? 'fadeOut' : 'fadeIn'})
  }

  render() {
    const { props } = this;
    return (
      <>
        <div onClick={() => this.toggle()} className="fh shadowHover r pointer">
          <div className="col-s-12 nop">
            <img alt=""  content="description" className="bauernImage" src={ROOT_URL_ORIGIN + props.image} />
          </div>
          <div className=" col-s-12 col-ms-12  ">
            <SubTitle>{props.name}</SubTitle>
            <Paragraph>{props.adress}</Paragraph>
            <Paragraph>{props.tel}</Paragraph>
          </div>
          <p  className="col-s-12 col-ms-12  primary-light">mehr erfahren</p>
        </div>
        <ModalboxContainer isMountedB={this.state.isMountedB} close={() => this.toggle()} adress={this.props.adress} tel={this.props.tel} name={this.props.name} description={this.props.description} image={this.props.image} animation={this.state.animation}/>
      </>
    );
  }
}

export class MilchBauernBig extends Component {
  state = {
    isMountedB: false,
    animation: 'fadeOut'
  }

  toggle = () => {
    this.setState({isMountedB: !this.state.isMountedB})
    this.setState({animation: this.state.animation === 'fadeIn' ? 'fadeOut' : 'fadeIn'})
  }

  render() {
    const { props } = this;
    return (
      <>
        <div onClick={() => this.toggle()} className="fh r pointer row">
          <div className="col-s-12 col-md-6 nop">
            <img alt=""  content="description" className="bauernImage" src={ROOT_URL_ORIGIN + props.image} />
          </div>
          <div className=" col-s-12 col-md-6">
            <SubTitle className="container nopl nopr nopt">{props.name}</SubTitle>
            <div dangerouslySetInnerHTML={{ __html: props.description }} className="br pt-half"></div>
          </div>

        </div>
        <ModalboxContainer isMountedB={this.state.isMountedB} close={() => this.toggle()} adress={this.props.adress} tel={this.props.tel} name={this.props.name} description={this.props.description} image={this.props.image} animation={this.state.animation}/>
      </>
    );
  }
}

export default MilchBauern;
