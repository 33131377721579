import { FETCH_WORLD} from '../../actions/type';

const initState = {}

export default function(state = initState, action) {
  switch(action.type) {
    case FETCH_WORLD:
    return action.data
    default:
      return state;
  }
}
