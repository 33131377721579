import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetch } from '../../../../redux/actions';

import { PageContainer } from '../../../reusable/styled/container';
import { Header } from '../../../reusable/styled/header';

import Logo from '../../../../media/logos/Logo.svg';
import BigImg from '../../../../media/header/header_milchautomat_big.jpg';
import MiddleImg from '../../../../media/header/header_milchautomat_medium.jpg';
import SmallImg from '../../../../media/header/header_milchautomat_small.jpg';

import { ROOT_URL, ROOT_URL_ORIGIN } from '../../../../config';

class Milchautomaten extends Component {
  componentDidMount() {
    if(!this.props.loaded) {
      this.props.fetch('fetch_vending', `${ROOT_URL}/api/singletons/get/milkvendingmachine?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  render() {
    return (
      <PageContainer>
        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Milchautomaten"
          ></Header>
        </div>

        <div className="container-big" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.vending.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.vending.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.vending.text
                }}
              ></div>
            )}
          </div>
        </div>

        <div
          className="container-big full-visible"
          style={{ paddingTop: "25px" }}
        >
          <div className="row">
            <div className="col-s-12 nopl nopr nopb">
              <div className="row flex-align-center">
                <div className="col-md-6">
                  <img
                    alt="Sonnenalm Milchautomat"
                    className="r"
                    src={
                      this.props.loaded &&
                      ROOT_URL_ORIGIN + this.props.vending.image.path
                    }
                  />
                </div>
                <div className="col-md-6">
                  {this.props.loaded && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.vending.description
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}


const mapStateToProps = (data) => {
  return {
    vending: data.vending,
    loaded: data.loaded.vending
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(Milchautomaten)
