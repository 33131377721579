import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetch } from '../../../../redux/actions';

import { Helmet } from 'react-helmet';
import { SubTitle } from '../../../reusable/styled/fonts';
import { PageContainer } from '../../../reusable/styled/container';
import { Header } from '../../../reusable/styled/header';
import Logo from '../../../../media/logos/Logo.svg';

import BigImg from '../../../../media/header/header_Käseschule_big.jpg';
import MiddleImg from '../../../../media/header/header_Käseschule_medium.jpg';
import SmallImg from '../../../../media/header/header_Käseschule_small.jpg';

import { ROOT_URL, ROOT_URL_ORIGIN } from '../../../../config';

class Käseschule extends Component {
  componentDidMount() {
    if(!this.props.loaded || !this.props.loadedOpentimes) {
      this.props.fetch('fetch_cheeseschool', `${ROOT_URL}/api/singletons/get/cheeseschool?token=b0e71c309917ffc064b1a14ea6a6cd`)
      this.props.fetch('fetch_opentimes_settings', `${ROOT_URL}/api/singletons/get/opentimes?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  renderPriceCardList = (data) => {
    return data.map((data, i) => {
      return (
        <>
          <p className="col-s-8 br ">{data.value.person}</p>
          <p className="col-s-4 ">{data.value.price}</p>
        </>
      )
    })
  }

  renderPriceCards = () => {
    if(this.props.loaded) {
      return this.props.cheeseschool.pricecard.map((data, i) => {
        return (
          <div className="col-lg-4 col-ml-5 col-md-6">
            <div className="card shadow-large modalboxContent-normal">
              <div className="modallist-container nop nos">
                <img
                  style={{ width: "100%", borderRadius: "16px" }}
                  alt="erlebniswelt bild"
                  src={`${ROOT_URL_ORIGIN}/cockpit-sonnenalm/storage/uploads/${data.value.image.path}`}
                />
                <div
                  className="padding-modal-produkt"
                  style={{ padding: "15px" }}
                >
                  <SubTitle className="black primary-dark container">
                    {data.value.title}
                  </SubTitle>
                  <p>{data.value.description}</p>
                  <p
                    style={{ paddingLeft: "17px", top: "30px" }}
                    className="bottom"
                  >
                    {data.value.dauer}
                  </p>
                  <div className="row pt-half">
                    <p className="col-s-8 br bb bold">Personen</p>
                    <p className="col-s-4 bb bold">Kosten €</p>

                    {this.renderPriceCardList(data.value.repeat)}
                  </div>
                </div>

              </div>
            </div>
          </div>
        );
      })
    }
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Entdecken - lernen - selber machen | Käseschule - Sonnenalm
          </title>
          <meta
            name="description"
            content="Sonnenalm Käseschule. Entdecken - lernen - selber machen | In der Käseschule haben die Besucher - nach Voranmeldung - die Möglichkeit sich unter Anleitung unseres Käsemeisters einmal selbst als Senner oder Sennerin zu versuchen. Nach ca. 4 - 5 Stunden kann jeder Teilnehmer seinen eigenen Käse mit nach Hause nehmrn."
          />
          <link
            rel="canonical"
            href="http://www.milcherlebniswelt.at/kaeseschule"
          />
        </Helmet>

        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Käseschule"
          ></Header>
        </div>

        <div className="container-big nopb" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.cheeseschool.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.cheeseschool.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.cheeseschool.description
                }}
              ></div>
            )}
          </div>
        </div>

        <div className="">
          <div className="container-big pt-half ">
            <div className="row justify-center">
              {this.renderPriceCards()}
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    cheeseschool: data.cheeseschool,
    loaded: data.loaded.cheeseschool,
    opentimes: data.opentimesSettings,
    loadedOpentimes: data.loaded.opentimes
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(Käseschule)
