import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROOT_URL } from '../../../../../config';
import { fetch } from '../../../../../redux/actions';
import { Accordion, Panel } from '../../../../reusable/styled/accordion';

class Faq extends Component {
  componentDidMount() {
    if(!this.props.loaded) {
      this.props.fetch('fetch_schoolmilk', `${ROOT_URL}/api/singletons/get/schoolmilk?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  renderHelper = () => {
    if(this.props.loaded) {
      return this.props.schoolmilk.faq.map((data, i) => {
        return (
          <Panel id={"a"+i} title={data.value.title} className="card-border pointer hoverAcc" titleStyle="primary">
            <div className="regular inner" dangerouslySetInnerHTML={{__html: data.value.content}}></div>
          </Panel>
        )
      })
    }
  }

  render() {
    return (
      <Accordion>
        {this.renderHelper()}
      </Accordion>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    loaded: data.loaded.schoolmilk,
    schoolmilk: data.schoolmilk
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(Faq)
