import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetch } from '../redux/actions';
import { NavLink } from 'react-router-dom';
import { NavItem, NavItemDropDown } from './navigation';
import { QuatroButton } from '../components/reusable/styled/buttons';

import { detectIE } from '../components/reusable/helper/functional/_browserDetect';

import Logo from '../media/logos/Logo.svg';

import { ROOT_URL } from '../config';

class DesktopNav extends Component {
  state = {
    width: window.innerWidth,
  }

  componentDidMount() {
    const version = detectIE();
    if (version <= 11 && version !== false) {
      const navId = document.getElementById("IE11FIX");
      navId.style.marginRight = '200px';
    }
    if(!this.props.loaded) {
      this.props.fetch('fetch_contact_settings', `${ROOT_URL}/api/singletons/get/contactsettings?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  render() {
    return (
      <nav className="flexbox no-wrap">
        <div className="justify-start flexbox">
          <img alt="Sonnenalm Logo" className="logo" src={Logo} />
        </div>
        <div className="flexbox fw justify-center">
          <div className="nop">
            <div className="row nop justify-center no-wrap fw">
              <NavItem to="/" content="Home" />
              <NavItemDropDown to="/produkte" content="Produkte">
                <p
                  onClick={() => window.scroll({ top: 0 })}
                  className="navlink smallN"
                >
                  <NavLink
                    activeClassName="avtiveNavlink"
                    to="/produkte/schulmilch"
                  >
                    Schulmilch
                  </NavLink>
                </p>
                <p
                  onClick={() => window.scroll({ top: 0 })}
                  className="navlink smallN"
                >
                  <NavLink
                    activeClassName="avtiveNavlink"
                    to="/produkte/milchautomaten"
                  >
                    Milchautomaten
                  </NavLink>
                </p>
                <p
                  onClick={() => window.scroll({ top: 0 })}
                  className="navlink smallN"
                >
                  <NavLink
                    activeClassName="avtiveNavlink"
                    to="/produkte/kuhmilchprodukte"
                  >
                    Kuhmilchprodukte
                  </NavLink>
                </p>
                <p
                  onClick={() => window.scroll({ top: 0 })}
                  className="navlink smallN"
                >
                  <NavLink
                    activeClassName="avtiveNavlink"
                    to="/produkte/ziegenmilchprodukte"
                  >
                    Ziegenmilchprodukte
                  </NavLink>
                </p>
              </NavItemDropDown>
              <NavItemDropDown to="/erlebniswelt" content="Erlebniswelt">
                <p
                  onClick={() => window.scroll({ top: 0 })}
                  className="navlink smallN"
                >
                  <NavLink
                    activeClassName="avtiveNavlink"
                    to="/milcherlebniswelt"
                  >
                    Milcherlebniswelt
                  </NavLink>
                </p>
                <p
                  onClick={() => window.scroll({ top: 0 })}
                  className="navlink smallN"
                >
                  <NavLink activeClassName="avtiveNavlink" to="/kaeseschule">
                    Käseschule
                  </NavLink>
                </p>
                <p
                  onClick={() => window.scroll({ top: 0 })}
                  className="navlink smallN"
                >
                  <NavLink activeClassName="avtiveNavlink" to="/ab-hof-verkauf">
                    Hofladen
                  </NavLink>
                </p>
              </NavItemDropDown>
              <NavItem to="/vertriebspartner" content="Vertriebspartner" />
              <NavItemDropDown to="/ueber-uns" content="Über uns">
                <p
                  onClick={() => window.scroll({ top: 0 })}
                  className="navlink smallN"
                >
                  <NavLink
                    activeClassName="avtiveNavlink"
                    to="/ueber-uns/produktion"
                  >
                    Produktion
                  </NavLink>
                </p>
                <p
                  onClick={() => window.scroll({ top: 0 })}
                  className="navlink smallN"
                >
                  <NavLink
                    activeClassName="avtiveNavlink"
                    to="/ueber-uns/unsere-bauern"
                  >
                    Unsere Bauern
                  </NavLink>
                </p>
              </NavItemDropDown>
            </div>
          </div>
        </div>
        <div id="IE11FIX" className="flexbox justify-end">
          <div className="nop">
            <div className="row nop no-wrap justify-center fw">
              <div className="nav-item-container flexbox flex-align-center">
                {this.props.width > 1187 ? (
                  <p
                    className="navlink smallN fix-width"
                    style={{ fontSize: "calc(10pt + 0.2rem)" }}
                  >
                    <a
                      href={`tel:${this.props.loaded &&
                        this.props.contact.tel}`}
                    >
                      Telefon: {this.props.loaded && this.props.contact.tel}{" "}
                    </a>
                  </p>
                ) : null}
              </div>
              <div className="nav-item-container flexbox flex-align-center">
                <div
                  onClick={() => window.scroll({ top: 0 })}
                  className="navlink smallN"
                >
                  <NavLink activeClassName="avtiveNavlink" exact to="/kontakt">
                    <QuatroButton content="Kontakt" className="rounded" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    loaded: data.loaded.contact,
    contact: data.contactSettings
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(DesktopNav)
