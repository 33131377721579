import { FETCH_SCHULMILCH_DOWNLOADS } from '../actions/type';

const initState = [
  {name: '', pdf: ''},
  {name: '', pdf: ''},
  {name: '', pdf: ''},
  {name: '', pdf: ''},
  {name: '', pdf: ''},
]

export default function(state = initState, action) {
  switch(action.type) {
    case FETCH_SCHULMILCH_DOWNLOADS:
      return action.data.entries;
    default:
      return state;
  }
}
