import React, { Component } from 'react';
import { Switch, Route } from 'react-router';

import Home from '../components/pages/home';

import Schulmilch from '../components/pages/products/schulmilch';
import Kuhmilch from '../components/pages/products/kuhmilch';
import Ziegenmilch from '../components/pages/products/ziegenmilch';
import Milchautomaten from '../components/pages/products/milchautomaten';
import Produkte from '../components/pages/products';

import Erlebniswelt from '../components/pages/erlebniswelt';
import Milcherlebniswelt from '../components/pages/erlebniswelt/milcherlebniswelt';
import Käseschule from '../components/pages/erlebniswelt/käseschule';
import Hofladen from '../components/pages/erlebniswelt/hofladen';

import Bauern from '../components/pages/about/bauern';
import Philosophy from '../components/pages/about/philosophie';
import Produktion from '../components/pages/about/production';
import About from '../components/pages/about';

import Contact from '../components/pages/contact';

import Partner from '../components/pages/vertriebspartner';
import Impressum from '../components/pages/impressum';
import Datenschutz from '../components/pages/datenschutz';
import Agb from '../components/pages/agb';

import ERROR404 from '../components/pages/error404';

export class Main extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/produkte/schulmilch" component={Schulmilch} />
        <Route exact path="/produkte/kuhmilchprodukte" component={Kuhmilch} />
        <Route exact path="/produkte/milchautomaten" component={Milchautomaten} />
        <Route exact path="/produkte/ziegenmilchprodukte" component={Ziegenmilch} />
        <Route exact path="/produkte" component={Produkte} />

        <Route exact path="/ueber-uns/unsere-bauern" component={Bauern} />
        <Route exact path="/ueber-uns/philosophie" component={Philosophy} />
        <Route exact path="/ueber-uns/produktion" component={Produktion} />
        <Route exact path="/ueber-uns" component={About} />

        <Route exact path="/vertriebspartner" component={Partner} />

        <Route exact path="/kontakt" component={Contact} />

        <Route exact path="/erlebniswelt" component={Erlebniswelt} />
        <Route exact path="/milcherlebniswelt" component={Milcherlebniswelt} />
        <Route exact path="/ab-hof-verkauf" component={Hofladen} />
        <Route exact path="/kaeseschule" component={Käseschule} />


        <Route exact path="/kontakt" component={Home} />

        <Route exact path="/impressum" component={Impressum} />
        <Route exact path="/datenschutz" component={Datenschutz} />
        <Route exact path="/agb" component={Agb} />

        <Route exact path="/" component={Home} />
        <Route path="/" component={ERROR404} />
      </Switch>
    )
  }
};
