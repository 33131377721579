export const FETCH_SCHULMILCH = 'fetch_schulmilch';
export const FETCH_SCHULMILCH_DOWNLOADS = 'fetch_schulmilch_downloads';
export const FETCH_KUHMILCHBAUERN = 'fetch_kuhmilchbauern';
export const FETCH_ZIEGENMILCHBAUERN = 'fetch_ziegenmilchbauern';
export const FETCH_KUHMILCHPRODUKTE = 'fetch_kuhmilchprodukte';
export const FETCH_ZIEGENMILCHPRODUKTE = 'fetch_ziegenmilchprodukte';
export const FETCH_PARTNER = 'fetch_partner';
export const FETCH_ANSPRECHPARTNER = 'fetch_ansprechpartner';

export const FETCH_HOME = 'fetch_home';
export const FETCH_PRODUCTS = 'fetch_products';
export const FETCH_VENDING = 'fetch_vending';
export const FETCH_COWMILK = 'fetch_cowmilk';
export const FETCH_GOATMILK = "fetch_goatmilk";
export const FETCH_UNSEREBAUERN = "fetch_unsereBauern";
export const FETCH_WORLD = 'fetch_world';
export const FETCH_STORE = 'fetch_store';
export const FETCH_CHEESESCHOOL = 'fetch_cheeseschool';
export const FETCH_MILKWORLD = 'fetch_milkworld';
export const FETCH_SCHOOLMILK = 'fetch_schoolmilk';
export const FETCH_ABOUT = 'fetch_about';
export const FETCH_PHILOSOPHY = 'fetch_philosophy';
export const FETCH_PRODUCTION = 'fetch_production';
export const FETCH_PARTNER_S = 'fetch_partner_s';
export const FETCH_CONTACTS = "fetch_contacts";

export const FETCH_CONTACT_SETTINGS = 'fetch_contact_settings';
export const FETCH_OPENTIMES_SETTINGS = 'fetch_opentimes_settings';
