import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { NavItem } from './navigation';

import { connect } from 'react-redux';
import { fetch } from '../redux/actions';

import { ROOT_URL } from "../config";

import Logo from '../media/logos/Logo.svg';

class MobileNav extends Component {
  state = {
    width: window.innerWidth,
    mobileOpen: false
  };

  componentDidMount() {
    if (!this.props.loaded) {
      this.props.fetch(
        "fetch_contact_settings",
        `${ROOT_URL}/api/singletons/get/contactsettings?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
  }

  openSimple = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  toggleNav = () => {
    window.scroll({ top: 0 });
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    return (
      <div>
        <div className="mobileNav">
          <div className="row">
            <img alt="Sonnenalm Logo" className="logo pointer" src={Logo} />
            <div onClick={this.openSimple} className="navIcon">
              <button
                className={
                  this.state.mobileOpen
                    ? "hamburger hamburger--collapse is-active"
                    : "hamburger hamburger--collapse"
                }
                type="button"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className={"mobile-nav-container " + this.state.mobileOpen}>
          <div className="nav-item-container">
            <span onClick={this.toggleNav}>
              <NavItem to="/" content="Home" />
            </span>
            <DropDown
              toggleNav={this.toggleNav}
              content="Produkte"
              to="/produkte"
              id="first"
            >
              <span onClick={this.toggleNav}>
                <NavItem to="/produkte/schulmilch" content="Schulmilch" />
              </span>
              <span onClick={this.toggleNav}>
                <NavItem
                  to="/produkte/milchautomaten"
                  content="Milchautomaten"
                />
              </span>
              <span onClick={this.toggleNav}>
                <NavItem
                  to="/produkte/kuhmilchprodukte"
                  content="Kuhmilchprodukte"
                />
              </span>
              <span onClick={this.toggleNav}>
                <NavItem
                  to="/produkte/ziegenmilchprodukte"
                  content="Ziegenmilchprodukte"
                />
              </span>
            </DropDown>
            <DropDown
              toggleNav={this.toggleNav}
              content="Erlebniswelt"
              to="/erlebniswelt"
              id="second"
            >
              <span onClick={this.toggleNav}>
                <NavItem to="/milcherlebniswelt" content="Milcherlebniswelt" />
              </span>
              <span onClick={this.toggleNav}>
                <NavItem to="/kaeseschule" content="Käseschule" />
              </span>
              <span onClick={this.toggleNav}>
                <NavItem to="/ab-hof-verkauf" content="Hofladen" />
              </span>
            </DropDown>
            <span onClick={this.toggleNav}>
              <NavItem to="/vertriebspartner" content="Vertriebspartner" />
            </span>
            <DropDown
              toggleNav={this.toggleNav}
              content="Über uns"
              to="/ueber-uns"
              id="third"
            >
              <span onClick={this.toggleNav}>
                <NavItem to="/ueber-uns/produktion" content="Produktion" />
              </span>
              <span onClick={this.toggleNav}>
                <NavItem
                  to="/ueber-uns/unsere-bauern"
                  content="Unsere Bauern"
                />
              </span>
            </DropDown>
            <span onClick={this.toggleNav}>
              <NavItem to="/kontakt" content="Kontakt" />
            </span>
          </div>
          <div className="mobileNav-bottom-container row justify-space-evenly">
            <span onClick={this.toggleNav}>
              <NavLink to="/impressum" content="Impressum">
                Impressum
              </NavLink>
            </span>
            <span onClick={this.toggleNav}>
              <NavLink to="/datenschutz" content="Impressum">
                Datenschutz
              </NavLink>
            </span>
            <span onClick={this.toggleNav}>
              <NavLink to="/agb" content="Impressum">
                AGB
              </NavLink>
            </span>
          </div>
        </div>
      </div>
    );
  }
}


class DropDown extends Component {
  state = {
    isOpen: false,
  }

  toggleDropDown = () => {
    this.setState({isOpen: !this.state.isOpen})

    const myId = this.props.id;
    var dH = document.getElementById(myId);
    if(this.state.isOpen) {
      dH.style.height = '0px';
    } else {
      dH.style.height = dH.scrollHeight + 'px';
    }

  }

  render() {
    return (
      <div className="row">
        <span className="col-s-6 nop" onClick={this.props.toggleNav}>
          <NavItem to={this.props.to} content={this.props.content}/>
        </span>
        <div className="dropDownMobile col-s-6" onClick={this.toggleDropDown}>
          <div className={this.state.isOpen ? "wrapper active" : "wrapper"}>
            <div className="arrow"></div>
          </div>

        </div>
        <div height={this.state.height} id={this.props.id} className={"mobileDropDown-container " + this.state.isOpen}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    loaded: data.loaded.contact,
    contact: data.contactSettings
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(MobileNav)
