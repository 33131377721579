import { combineReducers } from 'redux';

import schulmilchReducer from './schulmilch-reducer';
import schulmilchDownloadsReducer from './schulmilch-downloads-reducer';
import bauernReducer from './bauern-reducer';
import loadedReducer from './loaded-reducer';
import kuhmilchprodukteReducer from './kuhmilchprodukte-reducer';
import ziegenmilchprodukteReducer from './ziegenmilchprodukte-reducer';
import partnerReducer from './partner-reducer';
import ansprechpartnerReducer from './ansprechpartner-reducer';

import homePageReducer from './staticPages/homeReducer';
import productsPageReducer from './staticPages/productsReducer';
import milkVendingReducer from './staticPages/milkVendingReducer';
import worldReducer from './staticPages/worldReducer';
import milkworldReducer from './staticPages/milkworldReducer';
import cheeseschoolReducer from './staticPages/cheeseschoolReducer';
import storeReducer from './staticPages/storeReducer';
import schoolmilkReducer from './staticPages/schoolmilkReducer';
import aboutReducer from './staticPages/aboutReducer';
import philosophyReducer from './staticPages/philosophyReducer';
import productionReducer from './staticPages/productionReducer';
import partnerSReducer from './staticPages/partnerReducer';
import cowmilkReducer from "./staticPages/cowmilkReducer";
import goatmilkReducer from "./staticPages/goatmilkReducer";
import unsereBauernReducer from "./staticPages/unsereBauernReducer";
import contactsReducer from "./staticPages/contactsReducer";

import opentimesReducer from './settings/opentimesReducer';
import contactReducer from './settings/contactReducer';

const rootReducer = combineReducers({
  schulmilch: schulmilchReducer,
  schulmilchDownloads: schulmilchDownloadsReducer,
  bauern: bauernReducer,
  loaded: loadedReducer,
  kuhmilchprodukte: kuhmilchprodukteReducer,
  cowmilk: cowmilkReducer,
  goatmilk: goatmilkReducer,
  ziegenmilchprodukte: ziegenmilchprodukteReducer,
  partner: partnerReducer,
  partnerS: partnerSReducer,
  ansprechpartner: ansprechpartnerReducer,
  home: homePageReducer,
  products: productsPageReducer,
  vending: milkVendingReducer,
  world: worldReducer,
  milkworld: milkworldReducer,
  unsereBauern: unsereBauernReducer,
  cheeseschool: cheeseschoolReducer,
  store: storeReducer,
  schoolmilk: schoolmilkReducer,
  about: aboutReducer,
  opentimesSettings: opentimesReducer,
  contactSettings: contactReducer,
  philosophy: philosophyReducer,
  production: productionReducer,
  contacts: contactsReducer
});

export default rootReducer;
