import React from 'react';

import { PageContainer, HeadlineContainer } from '../reusable/styled/container';
import { Title, Paragraph } from '../reusable/styled/fonts';

const Impressum = () => (
  <PageContainer>
    <div className="pt container-big full-visible">
      <div className="row pt">
        <div className="col-lg-12">
          <HeadlineContainer>
            <Title>Impressum</Title>
          </HeadlineContainer>
          <Paragraph className="header-max-width-p">
            <span className="bold">Sonnenalm Bäuerlicher Milchhof e.Gen. </span><br />
            Milchstraße 1 <br />
            9373 Klein St. Paul <br />
            Tel. +43/4264/2716-0 <br />
            Fax +43/4264/2716-4 <br />
            <a className="link-light" href="mailto:office@sonnenalm-milch.at">office@sonnenalm-milch.at</a> <br />
            <a className="link-light" href="https://www.milcherlebniswelt.at">www.milcherlebniswelt.at</a>
          </Paragraph>
        </div>
        <div className="col-lg-12 pt">
          <HeadlineContainer>
            <Title>Rechtliche Information</Title>
          </HeadlineContainer>
          <Paragraph>
          Die auf dieser Webseite dargebotenen Inhalte dienen lediglich der allgemeinen Informationen über die Aktivitäten, Interessen und Zielsetzungen der Sonnenalm bäuerlicher Milchhof e.Gen.. Die angebotenen Informationen wurden sorgfältig überprüft, die Site-Betreiber übernehmen jedoch keine Garantie für die Richtigkeit, Vollständigkeit und Aktualität der Beiträge. Ebenso übernehmen sie keine Haftung für jegliche Entscheidungen und Handlungen, die im Vertrauen auf den Inhalt dieser Website gemacht werden.
          Ausschluss der Link-Haftung: Weiter haften die Seitenbetreiber nicht für den Inhalt externer Webseiten, die über Links von dieser Webseite aus erreicht werden können oder die ihrerseits auf diese Webseite verweisen. Inhaltliche Änderungen an diesen Webseiten werden ohne Ankündigung vorgenommen eine Haftung ist daraus nicht ableitbar.
          Datenschutz: Der überwiegende Teil des Informationsangebotes kann ohne Bekanntgabe persönlicher Daten besucht werden. Wir respektieren Ihre Privatsphäre und unternehmen alles technisch mögliche, damit eine Gefährdung der Datensicherheit ausgeschlossen werden kann.
          Copyright:
          Sämtliche Inhalte © by Sonnenalm Bäuerlicher Milchhof e.Gen.
        </Paragraph>
        </div>
      </div>
    </div>
  </PageContainer>
);


export default Impressum;
