import { FETCH_ANSPRECHPARTNER} from '../actions/type';

const initState = {}

export default function(state = initState, action) {
  switch(action.type) {
    // case FETCH_ANSPRECHPARTNER:
    // console.log(action.data);
    //   return {...state, ansprechpartner: action.data.entries};
    // default:
    //   return state;
    case FETCH_ANSPRECHPARTNER:
    return action.data.entries.map(data => {
      return (
        {
          image: data.image,
          name: data.name,
          bezeichnung: data.bezeichnung,
          tel: data.tel,
          mail: data.mail,
        }
      )
    })
    default:
      return state;
  }
}
