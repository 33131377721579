import { FETCH_PARTNER_S } from "../../actions/type";

const initState = [];

export default function(state = initState, action) {
  switch (action.type) {
    case FETCH_PARTNER_S:
      return action.data
    default:
      return state;
  }
}
