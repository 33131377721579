import React, { Component } from "react";
import Helmet from "react-helmet";

import { connect } from "react-redux";
import { fetch } from "../../../redux/actions";

import {
  PageContainer,
  HeadlineContainer
} from "../../reusable/styled/container";
import { Title, Paragraph, SubTitle } from "../../reusable/styled/fonts";
import { Header } from "../../reusable/styled/header";
import PartnerRender from "./partner-render";
import VertriebsPartner from "./partner";
import Logo from "../../../media/logos/Logo.svg";

import Spar from "../../../media/logos/spar-logo-neu.jpg";
import Billa from "../../../media/logos/billa-logo-neu.jpg";
import Adeg from "../../../media/logos/adeg-logo-neu.jpg";
import Merkur from "../../../media/logos/merkur-logo.jpg";

import BigImg from "../../../media/header/header_Vertriebspartner_big.jpg";
import MiddleImg from "../../../media/header/header_Vertriebspartner_medium.jpg";
import SmallImg from "../../../media/header/header_Vertriebspartner_small.jpg";

import { ROOT_URL } from "../../../config";

class Partner extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      this.props.fetch(
        "fetch_partner_s",
        `${ROOT_URL}/api/singletons/get/partner?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Hier finden Sie unsere Produkte | Vertriebspartner - Sonnenalm
          </title>
          <meta
            name="description"
            content="SO einfach finden Sie unsere Produkte - Klagenfurt - Bezirk Klagenfurt - St.Veit/Glan - Wolfsberg - Feldkirchen - Steiermark - Bezirk St.Veit an der Glan - Villach - Spittal an der Drau"
          />
          <link
            rel="canonical"
            href="http://www.milcherlebniswelt.at/vertriebspartner"
          />
        </Helmet>

        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Erlebniswelt"
          ></Header>
        </div>

        <div className="container-big" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.partner.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.partner.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.partner.description
                }}
              ></div>
            )}
          </div>
        </div>

        <div
          className="container-big full-visible"
          style={{ paddingTop: "50px" }}
        >
          <div className="row">
            <div className="col-lg-12">
              <SubTitle className="black">Kärntenweit finden Sie unsere Produkte <br /> bei folgenden Vertriebspartnern</SubTitle>
            </div>
            <div className="col-lg-3">
              <img alt="Logo Spar" className="logoborder" src={Spar} />
            </div>
            <div className="col-lg-3">
              <img alt="Logo Billa" className="logoborder" src={Billa} />
            </div>
            <div className="col-lg-3">
              <img alt="Logo Adeg" className="logoborder" src={Adeg} />
            </div>
            <div className="col-lg-3">
              <img alt="Logo Merkur" className="logoborder" src={Merkur} />
            </div>
            <div className="col-lg-12 primary-light">
              <Paragraph className="align-center">
                Zusätzlich finden Sie unsere Produkte im Spar, Interspar und
                Eurospar in Osttirol
              </Paragraph>
            </div>
            <div className="container-big nop">
              <div className="row" style={{ paddingTop: "50px" }}>
                <PartnerRender />
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}

const mapStateToProps = data => {
  return {
    partner: data.partnerS,
    loaded: data.loaded.partnerS
  };
};

export default connect(mapStateToProps, { fetch })(Partner);
