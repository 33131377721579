import React from 'react';
import "./css-lib/index.min.css";
import "./css/hamburger.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Main } from './router';

import Navigation from './router/navigation';
import Footer from './router/footer';


function App() {
  return (
    <>
    <Navigation />
    <Main />
    <Footer />
    </>
  );
}

export default App;
