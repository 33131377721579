import {
  FETCH_SCHULMILCH,
  FETCH_SCHULMILCH_DOWNLOADS,
  FETCH_KUHMILCHBAUERN,
  FETCH_ZIEGENMILCHBAUERN,
  FETCH_KUHMILCHPRODUKTE,
  FETCH_COWMILK,
  FETCH_ZIEGENMILCHPRODUKTE,
  FETCH_PARTNER,
  FETCH_ANSPRECHPARTNER,
  FETCH_HOME,
  FETCH_PRODUCTS,
  FETCH_VENDING,
  FETCH_WORLD,
  FETCH_STORE,
  FETCH_CHEESESCHOOL,
  FETCH_MILKWORLD,
  FETCH_SCHOOLMILK,
  FETCH_ABOUT,
  FETCH_PARTNER_S,
  FETCH_CONTACT_SETTINGS,
  FETCH_OPENTIMES_SETTINGS,
  FETCH_GOATMILK,
  FETCH_UNSEREBAUERN,
  FETCH_CONTACTS,
  FETCH_PRODUCTION,
} from '../actions/type';

const initState = {
  schulmilch: false,
  schulmilchDownloads: false,
  kuhmilchbauern: false,
  ziegenmilchbauern: false,
  goatmilk: false,
  kuhmilchprodukte: false,
  kuhmilchcowmilkprodukte: false,
  ziegenmilchprodukte: false,
  partner: false,
  ansprechpartner: false,
  home: false,
  products: false,
  production: false,
  vending: false,
  world: false,
  store: false,
  cheeseschool: false,
  milkworld: false,
  schoolmilk: false,
  unsereBauern: false,
  about: false,
  partnerS: false,
  contact: false,
  contacts: false,
  opentimes: false,
}

export default function(state = initState, action) {
  switch (action.type) {
    case FETCH_SCHULMILCH:
      return { ...state, schulmilch: true };
    case FETCH_KUHMILCHBAUERN:
      return { ...state, kuhmilchbauern: true };
    case FETCH_ZIEGENMILCHBAUERN:
      return { ...state, ziegenmilchbauern: true };
    case FETCH_SCHULMILCH_DOWNLOADS:
      return { ...state, schulmilchDownloads: true };
    case FETCH_KUHMILCHPRODUKTE:
      return { ...state, kuhmilchprodukte: true };
    case FETCH_COWMILK:
      return { ...state, cowmilk: true };
    case FETCH_GOATMILK:
      return { ...state, goatmilk: true };
    case FETCH_ZIEGENMILCHPRODUKTE:
      return { ...state, ziegenmilchprodukte: true };
    case FETCH_PARTNER:
      return { ...state, partner: true };
    case FETCH_ANSPRECHPARTNER:
      return { ...state, ansprechpartner: true };
    case FETCH_HOME:
      return { ...state, home: true };
    case FETCH_PRODUCTS:
      return { ...state, products: true };
    case FETCH_PRODUCTION:
      return { ...state, production: true };
    case FETCH_VENDING:
      return { ...state, vending: true };
    case FETCH_WORLD:
      return { ...state, world: true };
    case FETCH_STORE:
      return { ...state, store: true };
    case FETCH_CHEESESCHOOL:
      return { ...state, cheeseschool: true };
    case FETCH_MILKWORLD:
      return { ...state, milkworld: true };
    case FETCH_SCHOOLMILK:
      return { ...state, schoolmilk: true };
    case FETCH_ABOUT:
      return { ...state, about: true };
    case FETCH_UNSEREBAUERN:
      return { ...state, unsereBauern: true };
    case FETCH_PARTNER_S:
      return { ...state, partnerS: true };
    case FETCH_CONTACT_SETTINGS:
      return { ...state, contact: true };
    case FETCH_CONTACTS:
      return { ...state, contacts: true };
    case FETCH_OPENTIMES_SETTINGS:
      return { ...state, opentimes: true };
    default:
      return state;
  }
}
