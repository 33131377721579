import { FETCH_PARTNER} from '../actions/type';

const initState = [
]

export default function(state = initState, action) {
  switch(action.type) {
    case FETCH_PARTNER:
      return action.data.entries.map(data => {
        return (
          {
            bezirk: data.bezirk,
            partner: data.partner.map(data => {
              return {
                name: data.value.name,
                link: data.value.link,
              }
            })
          }
        )
      })
    default:
      return state;
  }
}
