import { FETCH_STORE} from '../../actions/type';

const initState = {}

export default function(state = initState, action) {
  switch(action.type) {
    case FETCH_STORE:
    return action.data
    default:
      return state;
  }
}
