import React from 'react';

export const PageTitle = (props) => (
  <h1 className={"large set-font-sp " + props.className}>{props.children}</h1>
);

export const Title = (props) => (
  <h2 className={"middle " + props.className}>{props.children}</h2>
);

export const SubTitle = (props) => (
  <h6 className={"small black set-font-primary " + props.className}>{props.children}</h6>
);

export const Paragraph = (props) => (
  <p className={"regular " + props.className}>{props.children}</p>
);

export const SmallParagraph = (props) => (
  <p className={"regular small " + props.className}>{props.children}</p>
);
