import React, { Component } from 'react';
import { ROOT_URL } from '../../../../config';
import Helmet from 'react-helmet';
import { fetch } from '../../../../redux/actions';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Title, Paragraph, SubTitle, SmallParagraph } from '../../../reusable/styled/fonts';
import { PageContainer } from '../../../reusable/styled/container';
import { Header } from '../../../reusable/styled/header';

import Logo from '../../../../media/logos/Logo.svg';

import BigImg from '../../../../media/header/header_Philosophie_big.jpg';
import MiddleImg from '../../../../media/header/header_Philosophie_medium.jpg';
import SmallImg from '../../../../media/header/header_Philosophie_small.jpg';

import Geschaeft from '../../../../media/images/geschaeft.jpg'
import Energie from '../../../../media/images/energie.jpg'
import Zechner from '../../../../media/images/avatar_mann.png'

class Philosophy extends Component{
  componentDidMount() {
    if(!this.props.loaded) {
      this.props.fetch('fetch_philosophy', `${ROOT_URL}/api/singletons/get/about?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  render() {
    return(
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Unsere Werte und Philosophie | Philosophie - Sonnenalm</title>
          <meta name="description" content="Drei Grundwerte bestimmen unser Handeln -
            Ein fairer Lohn für die Arbeit.
            Arbeit, die unseren Mitarbeitern freude macht.
          Herstellung echter Lebensmittel für jene, die sie wertschätzen - unsere Kunden" />
          <link rel="canonical" href="http://www.milcherlebniswelt.at/ueber-uns/philosophie" />
        </Helmet>

        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Philosophie"
          >
          </Header>
        </div>

        <div className="container-big">
          <div className="row pt">
            <div className="col-md-12">
              <Title className="align-center">
                Bäuerlicher Milchhof Sonnenalm eine <br /> regionale Erfolgsgeschichte
              </Title>
            </div>
          </div>
          <div className="row pt-half">
            <div className="col-s-12">
              <Paragraph className="align-center container nop">
                <span className="bold">Was im Jahr 1997</span> mit einer Hand voll Schulen als Kunden <span className="bold">begann</span>, hat sich in den letzten Jahren zu einer regionalen Erfolgsgeschichte entwickelt. Das Projekt Sonnenalm ist aus der Not heraus entstanden, da es nach dem Beitritt Österreichs zur EU zu einem dramatischen Milchpreisverfall für die Bauern kam.
                <br /> <br />
                Eine Gruppe von Bauern in der Region Görtschitztal nahmen damals ihr Schicksal selbst in die Hand und gründeten den Bäuerlichen Milchhof Sonnenalm in Klein St. Paul.
              </Paragraph>
            </div>
          </div>
        </div>

        <div className="container-big">
          <div className="row pt flex-align-center">
            <div className="col-md-3">
              <img alt="portrait Hannes Zechner" className="r" src={Zechner} />
            </div>
            <div className="col-md-8">
              <SubTitle>Hannes Zechner</SubTitle>
              <Paragraph>Gründer & Obmann - Sonnenalm Bäuerlicher Milchhof</Paragraph>

              {/* HIER STEHT ZITAT TEXT */}
              <h3 className="middle pt-half">"Milch ist ein ganz besonderer Saft"</h3>
              <SmallParagraph>(Frei nach Johann Wolfgang von Goethe und Hannes Zechner)</SmallParagraph>
            </div>
          </div>
        </div>

        <div className="container-big">
          <div className="row pt">
            <div className="col-md-4">
              <img alt="" className="r" src={Energie} />
            </div>
            <div className="col-md-6">
              <Title>
                Wir sind energieautark
              </Title>
              <Paragraph className="pt-half">
                Die gesamte in unserem Betrieb benötigte Energie wird aus 100% erneuerbaren Energieformen selbst erzeugt!

                Unser eigenes Biomasseheizwerk mit 200 kW thermischer Leistung liefert uns die nötige Wärme für die Pasteurisierung, Käserei, Reinigung und Heizung. Das Waldhackgut dafür liefern unsere Bauern aus der Umgebung!

                Die Photovoltaikanlage auf unserem Dach erzeugt den nötigen elektrischen Strom aus Sonnenenergie!
              </Paragraph>
            </div>
          </div>
        </div>

      </PageContainer>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    loaded: data.loaded.philosophy,
    philosophy: data.philosophy
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(Philosophy)
