import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetch } from '../../../redux/actions';

import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Title, SubTitle } from '../../reusable/styled/fonts';
import { PageContainer, HeadlineContainer } from '../../reusable/styled/container';
import { PageLink } from '../../reusable/styled/pageLink';
import { Header } from '../../reusable/styled/header';
import Logo from '../../../media/logos/Logo.svg';

import Milcherlebniswelt from '../../../media/images/Milcherlebniswelt5_Button.jpg';
import Kaeseschule from '../../../media/images/kaeseschule_.jpg';
import Hofladen from '../../../media/images/hofladen_klein.jpg';

import BigImg from '../../../media/header/header_milcherlebniswelt_big.jpg';
import MiddleImg from '../../../media/header/header_milcherlebniswelt_medium.jpg';
import SmallImg from '../../../media/header/header_milcherlebniswelt_small.jpg';

import Fade from 'react-reveal/Fade';

import { ROOT_URL } from '../../../config';

class Erlebniswelt extends Component {
  componentDidMount() {
    if(!this.props.loaded) {
      this.props.fetch('fetch_world', `${ROOT_URL}/api/singletons/get/world?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Wie wird die Milch verarbeitet? | Erlebniswelt - Sonnenalm
          </title>
          <meta
            name="description"
            content="Einblicke in die Milchproduktion. Unsere gläserne Molkerei bietet Ihnen einzigartige Einblicke in die traditionelle Milchverarbeitung und hinter die Kulissen des Bäuerlichen Milchhofes Sonnenalm."
          />
          <link
            rel="canonical"
            href="http://www.milcherlebniswelt.at/milcherlebniswelt"
          />
        </Helmet>

        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Erlebniswelt"
          ></Header>
        </div>

        <div className="container-big" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.world.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.world.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.world.description
                }}
              ></div>
            )}
          </div>
        </div>

        <div className="container-big nopb">
          <div className="row">
            <div className="col-s-12">
              <div className="container-big nopb">
                <div className="row justify-center">
                  <div className="col-md-4 col-s-6 nop">
                    <NavLink onClick={() => window.scroll({ top: 0 })}>
                      <PageLink
                        to="/milcherlebniswelt"
                        backgroundImage={Milcherlebniswelt}
                      >
                        <p className="pageLinkText align-center">
                          Milcherlebniswelt
                        </p>
                      </PageLink>
                    </NavLink>
                  </div>
                  <div className="col-md-4 col-s-6 nop">
                    <NavLink onClick={() => window.scroll({ top: 0 })}>
                      <PageLink to="/kaeseschule" backgroundImage={Kaeseschule}>
                        <p className="pageLinkText align-center">Käseschule</p>
                      </PageLink>
                    </NavLink>
                  </div>
                  <div className="col-md-4 col-s-6 nop">
                    <NavLink onClick={() => window.scroll({ top: 0 })}>
                      <PageLink to="/ab-hof-verkauf" backgroundImage={Hofladen}>
                        <p className="pageLinkText align-center">Hofladen</p>
                      </PageLink>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}


const mapStateToProps = (data) => {
  return {
    loaded: data.loaded.world,
    world: data.world
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(Erlebniswelt)
