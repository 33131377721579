import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetch } from "../../../redux/actions";

import { Title, Paragraph, SubTitle } from "../../reusable/styled/fonts";
import {
  PageContainer,
  HeadlineContainer
} from "../../reusable/styled/container";
import { Header } from "../../reusable/styled/header";

import ProduktRender from "./schulmilch/produkte/produktRender";

import Milchautomat from "../../../media/images/Milchautomat.jpg";
import SchulmilchKinder from "../../../media/images/SchulmilchKinder.jpg";
import KuhmilchMockup from "../../../media/images/Mockup_Kuhmilch.png";
import ZiegenmilchMockup from "../../../media/images/Mockup_Ziegen.png";
import Logo from "../../../media/logos/Logo.svg";

import BigImg from "../../../media/header/header_Produkte_big.jpg";
import MiddleImg from "../../../media/header/header_Produkte_medium.jpg";
import SmallImg from "../../../media/header/header_Produkte_small.jpg";

import { ROOT_URL, ROOT_URL_ORIGIN } from "../../../config";

class Produkte extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      this.props.fetch(
        "fetch_products",
        `${ROOT_URL}/api/singletons/get/products?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Wir lieben unsere Produkte | Produkte - Sonnenalm</title>
          <meta
            name="description"
            content="Nur das beste seit 1997. Natürliche und Gesunde Milchprodukte der Sonnenalm"
          />
          <link
            rel="canonical"
            href="http://www.milcherlebniswelt.at/produkte"
          />
        </Helmet>

        <div className="divider-2">
          <Header imageB={BigImg} imageM={MiddleImg} imageS={SmallImg}></Header>
        </div>

        <div className="container-big" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.products.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.products.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.products.text
                }}
              ></div>
            )}
          </div>
        </div>

        <div className="container-big" style={{ paddingTop: "50px" }}>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-ms-12">
              <img
                style={{ borderRadius: "16px" }}
                alt="Bild von Sonnenalm Schulmilchaktion"
                src={
                  this.props.loaded ? ROOT_URL_ORIGIN + this.props.products.imageAction.path : undefined
                }
              />
            </div>
            <div className="col-lg-9 col-md-6 col-ms-12">
              <div>
                <h2
                  className="small set-font-primary"
                  style={{ fontWeight: "700" }}
                >
                  {this.props.products.titleAction}
                </h2>
                {this.props.loaded && (
                  <div
                    className="container nopl nopr nopb"
                    dangerouslySetInnerHTML={{
                      __html: this.props.products.textaction
                    }}
                  ></div>
                )}
              </div>
              <div
                className="pt-half from-rel-to-abs-ms"
                style={{ bottom: "0.9rem" }}
              >
                <p onClick={() => window.scroll({ top: 0 })}>
                  <NavLink to="/produkte/schulmilch">
                    <button className="btn-tertiary">
                      weiter zu Schulmilchprodukte
                    </button>
                  </NavLink>
                </p>
              </div>
            </div>

            <div
              className="container-big nopl nopr nopb"
              style={{ paddingTop: "75px" }}
            >
              <div className="row">
                <div className="col-lg-3 col-ml-6 col-ms-12">
                  <img
                    style={{ borderRadius: "16px" }}
                    alt="Bild von Sonnenalm Schulmilchaktion"
                    src={
                      this.props.loaded ? ROOT_URL_ORIGIN + this.props.products.imageCow.path : undefined
                    }
                  />
                </div>
                <div className="col-lg-9 col-ml-6 col-ms-12">
                  <div>
                    <h2
                      className="small set-font-primary"
                      style={{ fontWeight: "700" }}
                    >
                      {this.props.products.titleCow}
                    </h2>
                    {this.props.loaded && (
                      <div
                        className="container nopl nopr nopb"
                        dangerouslySetInnerHTML={{
                          __html: this.props.products.textcow
                        }}
                      ></div>
                    )}
                  </div>
                  <div
                    className="pt-half from-rel-to-abs-ms"
                    style={{ bottom: "0.9rem" }}
                  >
                    <p onClick={() => window.scroll({ top: 0 })}>
                      <NavLink to="/produkte/kuhmilchprodukte">
                        <button className="btn-tertiary">
                          weiter zu Kuhmilchprodukte
                        </button>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="container-big nopl nopr nopb"
              style={{ paddingTop: "75px" }}
            >
              <div className="row">
                <div className="col-lg-3 col-ml-6 col-ms-12">
                  <img
                    style={{ borderRadius: "16px" }}
                    alt="Bild von Sonnenalm Schulmilchaktion"
                    src={
                      this.props.loaded ? ROOT_URL_ORIGIN + this.props.products.imageGoat.path : undefined
                    }
                  />
                </div>
                <div className="col-lg-9 col-ml-6 col-ms-12">
                  <div>
                    <h2
                      className="small set-font-primary"
                      style={{ fontWeight: "700" }}
                    >
                      {this.props.products.titleGoat}
                    </h2>
                    {this.props.loaded && (
                      <div
                        className="container nopl nopr nopb"
                        dangerouslySetInnerHTML={{
                          __html: this.props.products.textgoat
                        }}
                      ></div>
                    )}
                  </div>
                  <div
                    className="pt-half from-rel-to-abs-ms"
                    style={{ bottom: "0.9rem" }}
                  >
                    <p onClick={() => window.scroll({ top: 0 })}>
                      <NavLink to="/produkte/ziegenmilchprodukte">
                        <button className="btn-tertiary">
                          weiter zu Ziegenmilchprodukte
                        </button>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="container-big nopl nopr nopb"
              style={{ paddingTop: "75px" }}
            >
              <div className="row">
                <div className="col-lg-3 col-ml-6 col-ms-12">
                  <img
                    style={{ borderRadius: "16px" }}
                    alt="Bild von Sonnenalm Schulmilchaktion"
                    src={
                      this.props.loaded ? ROOT_URL_ORIGIN + this.props.products.imageAutomate.path : undefined
                    }
                  />
                </div>
                <div className="col-lg-9 col-ml-6 col-ms-12">
                  <div>
                    <h2
                      className="small set-font-primary"
                      style={{ fontWeight: "700" }}
                    >
                      {this.props.products.titleAutomate}
                    </h2>
                    {this.props.loaded && (
                      <div
                        className="container nopl nopr nopb"
                        dangerouslySetInnerHTML={{
                          __html: this.props.products.textautomate
                        }}
                      ></div>
                    )}
                  </div>
                  <div
                    className="pt-half from-rel-to-abs-ms"
                    style={{ bottom: "0.9rem" }}
                  >
                    <p onClick={() => window.scroll({ top: 0 })}>
                      <NavLink to="/produkte/milchautomaten">
                        <button className="btn-tertiary">
                          weiter zu Milchautomaten
                        </button>
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}

const mapStateToProps = data => {
  return {
    products: data.products,
    loaded: data.loaded.products
  };
};

export default connect(mapStateToProps, { fetch })(Produkte);
