import React, { Component } from "react";
import { ROOT_URL, ROOT_URL_ORIGIN } from "../../../../config";
import Helmet from "react-helmet";
import { fetch } from "../../../../redux/actions";
import { connect } from "react-redux";

import { PageContainer } from "../../../reusable/styled/container";
import { Header } from "../../../reusable/styled/header";

import Logo from "../../../../media/logos/Logo.svg";

import BigImg from "../../../../media/header/header_Produktion_big.jpg";
import MiddleImg from "../../../../media/header/header_Produktion_medium.jpg";
import SmallImg from "../../../../media/header/header_Produktion_small.jpg";

class Produktion extends Component {
  componentDidMount() {
    if (!this.props.loaded) {
      this.props.fetch(
        "fetch_production",
        `${ROOT_URL}/api/singletons/get/production?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
  }

  renderProductionCols = () => {
    console.log(this.props.production.repeater);
    if (this.props.loaded) {
      return this.props.production.repeater.map(data => {
        return (
          <div className="col-md-3">
            <div
              style={{
                backgroundColor: "white",
                boxShadow: "0 0 99px rgba(0,0,0,0.2)",
                borderRadius: "16px"
              }}
            >
              <img
                alt=""
                className="r"
                src={`${ROOT_URL_ORIGIN}cockpit-sonnenalm/storage/uploads/${data.value.image.path}`}
              />
              <div className="col-s-12">
                <h6 className="small bold">{data.value.title}</h6>
                <div
                  style={{ paddingTop: "15px" }}
                  dangerouslySetInnerHTML={{ __html: data.value.text }}
                ></div>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  render() {
    return (
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Prdouktion mit modernster Technik | Produktion - Sonnenalm
          </title>
          <meta
            name="description"
            content="Damit Sie ihre frischen Sonnalm Produkte genießen können, müssen unsere Bauern und Bäuerinnen früh aufstehen – denn ab 7.00 Uhr in der Früh wird die frische Milch bereits von jedem Bauern selbst zur Sonnenalm angeliefert."
          />
          <link
            rel="canonical"
            href="http://www.milcherlebniswelt.at/ueber-uns/produktion"
          />
        </Helmet>

        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Produktion"
          ></Header>
        </div>

        <div className="container-big" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.production.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.production.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.production.description
                }}
              ></div>
            )}
          </div>
        </div>

        <div className="">
          <div className="container-big">
            <div className="row">
              <div className="row container-big nopl nopr nob pt-half justify-center">
                {this.renderProductionCols()}
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}

const mapStateToProps = data => {
  return {
    loaded: data.loaded.production,
    production: data.production
  };
};

export default connect(mapStateToProps, { fetch })(Produktion);
