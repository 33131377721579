import React, { Component } from "react";

import {
  PrimaryButton,
  SecondaryButton
} from "../../../../reusable/styled/buttons";
import {
  Paragraph,
  SmallParagraph,
  SubTitle
} from "../../../../reusable/styled/fonts";

import { ROOT_URL_ORIGIN } from "../../../../../config";

import { Naehrwerte, Allergene } from "../../../../../media/icons/file";

import { ModalboxContainer } from "../modalbox";

class Produkt extends Component {
  state = {
    isMountedN: false,
    isMountedA: false,
    animation: "fadeOut"
  };

  toggle = what => {
    what === 0
      ? this.setState({ isMountedN: !this.state.isMountedN })
      : this.setState({ isMountedA: !this.state.isMountedA });

    this.setState({
      animation: this.state.animation === "fadeIn" ? "fadeOut" : "fadeIn"
    });
  };

  tags = () => {
    if (this.props.tag) {
      return this.props.tag.map((data, i) => {
        if (data.toLowerCase() === "zuckerreduziert") {
          return (
            <div key={i} className="p-tag">
              <button className="btn-small-blue">
                <SmallParagraph>{data.toLowerCase()}</SmallParagraph>
              </button>
            </div>
          );
        }
        if (data === "Acidophilus") {
          return (
            <div key={i} className="p-tag">
              <button className="btn-small-blue-dark">
                <SmallParagraph>{data}</SmallParagraph>
              </button>
            </div>
          );
        }
        if (data.toUpperCase() === "BIO") {
          return (
            <div key={i} className="p-tag">
              <button className="btn-small-full">
                <SmallParagraph>{data.toUpperCase()}</SmallParagraph>
              </button>
            </div>
          );
        } else {
          return (
            <div key={i} className="p-tag">
              <button className="btn-small">
                <SmallParagraph>{data.toLowerCase()}</SmallParagraph>
              </button>
            </div>
          );
        }
      });
    }
  };

  buttons = () => {
    if (this.props.width > 504) {
      return (
        <>
          <PrimaryButton
            onClick={() => this.toggle(0)}
            className="nopl nopb"
            content="Nährwerte"
          />
          <SecondaryButton
            onClick={() => this.toggle(1)}
            className="nopl nopr nopb"
            content="Allergene"
          />
        </>
      );
    } else {
      return (
        <div className="container row nopl">
          <div className="btn-secondary-small">
            <Naehrwerte className="icon-p" onClick={() => this.toggle(0)} />
          </div>
          <div className="btn-secondary-small">
            <Allergene className="icon-p" onClick={() => this.toggle(1)} />
          </div>
        </div>
      );
    }
  };

  render() {
    const { props } = this;
    return (
      <>
        <div className="row schulmilch-produkt fh ">
          <div className="col-s-6 col-ms-12 nop fixHeightProducts">
            <img
              className="fixImageProducts"
              alt={props.produktName}
              src={ROOT_URL_ORIGIN + props.image}
            />
          </div>
          <div className="col-s-6 col-ms-12 nop">
            <div className="sp-produkt-pl">
              <SubTitle>{props.produktName}</SubTitle>
              {props.subTitle === "" || !props.subTitle ? (
                <SmallParagraph>
                  <span className="tertiary-light">-</span>
                </SmallParagraph>
              ) : (
                <SmallParagraph>{props.subTitle}</SmallParagraph>
              )}
              <div className="flexbox p26">{this.tags()}</div>
              <div className="container nopl nopr nopb">
                <Paragraph className="bold">{props.price}</Paragraph>
                <Paragraph className="bold">{props.amount}</Paragraph>
              </div>
              <div className="row nop">
                {/* REMEMBER DIE BUTTONS ZU ICONS AUSTAUSCHEN FÜR DUE MOBILE VERSION */}
                {this.buttons()}
              </div>
            </div>
          </div>
        </div>
        <ModalboxContainer
          isMountedN={this.state.isMountedN}
          close={() => this.toggle(0)}
          naehrwerte={props.naehrwerte}
          animation={this.state.animation}
        />
        <ModalboxContainer
          isMountedN={this.state.isMountedA}
          close={() => this.toggle(1)}
          allergene={props.allergene}
          animation={this.state.animation}
        />
      </>
    );
  }
}

export default Produkt;
