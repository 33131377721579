import React, { Component } from 'react';
import { Paragraph } from '../../reusable/styled/fonts';


class VertriebsPartner extends Component {

  renderHelper = () => {
    if(!this.props.loaded ) { return }
    return this.props.name.map((data, i) => {
      if(data.link !== undefined){
        return(
          <a key={i} className="primary-light" target="_blanc" href={'http://' + data.link}>
            <Paragraph>
              {data.name}
            </Paragraph>
          </a>
        );
      }
      return (
        <p className="regular" key={i}>
          {data.name}
        </p>
      )
    })
  }

  render() {
    const { props } = this;
    return (
        <div className="row inner">
          <div className=" col-s-12 col-ms-12 nopl nopr nopb">
            {this.renderHelper()}
          </div>
        </div>
    );
  }
}

export default VertriebsPartner;
