import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { ROOT_URL_ORIGIN } from '../../../../config';
import { SubTitle } from '../../../reusable/styled/fonts';
import { Phone, Mail } from '../../../../media/icons/file';
import { ROOT_URL_ORIGIN } from '../../../../config';

class Ansprechpartner extends Component {
  render() {
    const { props } = this;
    return (
      <>
        {/* <img src={ROOT_URL_ORIGIN + props.image} /> */}
        <div style={{height: '400px'}}>

        <img alt={props.name} className="r absolute" src={ROOT_URL_ORIGIN + props.image} style={{maxHeight: "400px", height: "100%", width: "auto", left: "50%", transform: "translateX(-50%)"}}/>
        </div>
        <div className="container ">
          <SubTitle>
            {props.name}
          </SubTitle>
          <p>
            {props.bezeichnung}
          </p>
        </div>
        <div className="container ">
          {props.tel === ''
            ? null
            : <div className="row flex-align-center container nopl nopr nopt"><Phone className="icon" /><p>{props.tel}</p></div>
          }
          {props.mail === ''
            ? null
            : <div className="row flex-align-center container nopl nopr nopt"><Mail className="icon" /><p>{props.mail}</p></div>
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    data
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(Ansprechpartner)
