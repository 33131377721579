import React from 'react';
import { NavLink } from 'react-router-dom';
import { Title } from '../components/reusable/styled/fonts';
import { PrimaryButton, SecondaryButton } from '../components/reusable/styled/buttons';

import Logo from '../media/logos/Logo.svg';

import FBIcon from '../media/footer/FB.png';
import GoatAndCow from '../media/footer/KuhZiege.png';

const Cp = new Date();

const Footer = () => (
  <div className="footer-background" style={{backgroundColor: 'transparent'}}>
    <div className="container-middle align-center pt-half">
      <div>
        <Title>
          <span className="black">SO</span> erreichen Sie uns:
        </Title>
        <div className="row justify-center container-big">
          <div>
            <a href="mailto:office@sonnenalm-milch.at">
              <PrimaryButton content="E-mail" />
            </a>
          </div>
          <div>
            <NavLink onClick={() => window.scroll({ top: 0 })} to="/kontakt">
              <SecondaryButton content="Kontakt" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>

    <div className="row container-big " >
      <div className="col-lg-4 col-ms-12 col-s-12 flexbox justify-center">
        <img alt="Sonnenalm Logo" src={Logo} className="footer-logo" />
      </div>
      <div className="col-lg-2 col-ms-3 col-s-6 sp-sp-small">
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/"
          >
            Home
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/produkte"
          >
            Produkte
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/produkte/schulmilch"
          >
            Schulmilch
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/produkte/milchautomaten"
          >
            Milchautomaten
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/produkte/kuhmilchprodukte"
          >
            Kuhmilchprodukte
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/produkte/ziegenmilchprodukte"
          >
            Ziegenmilchprodukte
          </NavLink>
        </p>
      </div>
      <div className="col-lg-2 col-ms-3 col-s-6 sp-sp-small">
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/milcherlebniswelt"
          >
            Milcherlebniswelt
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/ab-hof-verkauf"
          >
            Hofladen
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/kaeseschule"
          >
            Käseschule
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/ueber-uns/produktion"
          >
            Produktion
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/ueber-uns/unsere-bauern"
          >
            Unsere Bauern
          </NavLink>
        </p>
      </div>
      <div className="col-lg-2 col-ms-3 col-s-12 sp-sp-small">
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/kontakt"
          >
            Kontakt
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/impressum"
          >
            Impressum
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/datenschutz"
          >
            Datenschutz
          </NavLink>
        </p>
        <p>
          <NavLink
            className="link"
            onClick={() => window.scroll({ top: 0 })}
            to="/agb"
          >
            AGB
          </NavLink>
        </p>
      </div>
      {window.innerWidth > 575
        ? <div  className="col-lg-2 col-ms-3 col-s-12 sp-sp-small">
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Sonnenalm/"><img style={{width: '75px'}} alt="link zur facebook seite" src={FBIcon} /></a>
          <img style={{width: '135px', position: 'absolute', bottom: '-25px', left: '-60px'}} alt="" src={GoatAndCow} />
        </div> : null}

    </div>

    <div className="row copyright-area">
      <div className="col-lg-12 container-big">
        <p className="small">
          © {Cp.getFullYear()} Sonnenalm • design & programmierung by{" "}
          <a
            rel="noopener noreferrer"
            className="link small"
            href="https://www.designintime.at"
            target="_blank"
          >
            www.designintime.at
          </a>
        </p>
      </div>
    </div>
  </div>
);

export default Footer;
