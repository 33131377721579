import React, { Component } from 'react';
import Produkt from './kuhmilch-produkt';
import { connect } from 'react-redux';
import { ROOT_URL } from '../../../../config';
import { fetch } from '../../../../redux/actions';

class ProduktRender extends Component {
  state = {
    width: window.innerWidth
  };

  componentDidMount() {
    if (!this.props.loaded) {
      this.props.fetch(
        "fetch_kuhmilchprodukte",
        `${ROOT_URL}/api/collections/get/kuhmilchprodukte?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  renderHelper = category => {
    // if data isnt loaded exit the function
    if (!this.props.loaded) {
      return;
    }
    // if data is loaded render Produkts
    return this.props.kuhmilchprodukte.map((data, i) => {
      if (category === data.category) {
        return (
          <div key={i} className="col-lg-3 col-md-4 col-ms-6">
            <Produkt
              image={data.image.path}
              width={this.state.width}
              produktName={data.produktname}
              subTitle={data.subtitle}
              tag={data.tag}
              price={data.price}
              amount={data.amount}
              naehrwerte={data.naehrwerte}
              allergene={data.allergene}
            />
          </div>
        );
      }
    });
  };

  // <Produkt
  //             image={data.image.path}
  //             product={data.product}
  //             tag={data.tag}
  //             naehrwerte={data.naehrwerte}
  //             description={data.description}
  //           />

  render() {
    return (
      <>
        <div style={{ paddingTop: "75px" }} className="column col-s-12">
          <h2 className="bold small">Frischmilch</h2>
          <p>{this.props.loaded2 && this.props.cowmilk.milkDescription}</p>
        </div>
        {this.renderHelper("Frischmilch")}
        <div style={{ paddingTop: "100px" }} className="column col-s-12">
          <h2 className="bold small">Milchmixprodukte</h2>
          <p>{this.props.loaded2 && this.props.cowmilk.milkmixDescription}</p>
        </div>
        {this.renderHelper("Milchmixprodukte")}
        <div style={{ paddingTop: "100px" }} className="column col-s-12">
          <h2 className="bold small">Jogurt</h2>
          <p>{this.props.loaded2 && this.props.cowmilk.joghurtDescription}</p>
        </div>
        {this.renderHelper("Jogurt")}
        <div style={{ paddingTop: "100px" }} className="column col-s-12">
          <h2 className="bold small">Speisetopfen und Käse</h2>
          <p>{this.props.loaded2 && this.props.cowmilk.topfenDescription}</p>
        </div>
        {this.renderHelper("Topfen")}
        {this.renderHelper("Käse")}
      </>
    );
  }
}


const mapStateToProps = (data) => {
  return {
    loaded: data.loaded.kuhmilchprodukte,
    loaded2: data.loaded.cowmilk,
    kuhmilchprodukte: data.kuhmilchprodukte,
    cowmilk: data.cowmilk,
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(ProduktRender)
