import React from 'react';

import { File } from '../../../media/icons/file.js';

export const Download = (props) => (
  <div onClick={props.download} className="card-download pointer">
    <p className="bold innerText">{props.title}</p>
    <div className="download-icon-container">
      <File className="download-icon" />
    </div>
  </div>
);
