import React from 'react';

import Modalbox from '../../../../reusable/helper/functional/modalbox';
import { ModalBoxContent } from './modalContent';

export const ModalboxContainer = (props) => (
  <Modalbox
    delayTime={500}
    isMounted={props.isMountedB}
    modalContent={
      <ModalBoxContent
        image={props.image}
        close={props.close}
        description={props.description}
        name={props.name}
        tel={props.tel}
        adress={props.adress}
        animation={props.animation}
      />
    }>
  </Modalbox>
);
