import React, { Component } from 'react';
import MilchBauern from './bauern';
import { connect } from 'react-redux';
import { ROOT_URL } from '../../../../config';
import { fetch } from '../../../../redux/actions';
import { Title } from '../../../reusable/styled/fonts';

class BauernRender extends Component {
  componentDidMount() {
    if(!this.props.loadedK && !this.props.loadedZ) {
      this.props.fetch('fetch_kuhmilchbauern', `${ROOT_URL}/api/collections/get/kuhmilchbauern?token=b0e71c309917ffc064b1a14ea6a6cd`)
      this.props.fetch('fetch_ziegenmilchbauern', `${ROOT_URL}/api/collections/get/ziegenmilchbauern?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  renderHelperKuh = () => {
    // if data isnt loaded exit the function
    if(!this.props.loadedK) { return }
    // if data is loaded render Produkts
    return this.props.kuhmilchbauern.map((data, i) => {
      if(this.props.limit === true) {
        if(this.props.num > i) {
          return (
            <div key={i} className="col-lg-4 col-md-4 col-ms-6">
              <MilchBauern
                image={data.image.path}
                name={data.name}
                adress={data.adress}
                tel={data.tel}
                description={data.description}
              />
            </div>
          )
        } else {
          return null
        }
      } else {
        return (
          <div key={i} className="col-lg-4 col-md-4 col-ms-6">
            <MilchBauern
              image={data.image.path}
              name={data.name}
              adress={data.adress}
              tel={data.tel}
              description={data.description}
            />
          </div>
        )
      }

    })
  }
  renderHelperZiegen = () => {
    // if data isnt loaded exit the function
    if(!this.props.loadedZ) { return }
    // if data is loaded render Produkts
    return this.props.ziegenmilchbauern.map((data, i) => {
      if(this.props.limitZ === true) {
        if(this.props.numZ > i) {
          return (
            <div key={i} className="col-lg-4 col-md-4 col-ms-6">
              <MilchBauern
                image={data.image.path}
                name={data.name}
                adress={data.adress}
                tel={data.tel}
                description={data.description}
              />
            </div>
          )
        } else {
          return null
        }
      } else {
        return (
          <div key={i} className="col-lg-4 col-md-4 col-ms-6">
            <MilchBauern
              image={data.image.path}
              name={data.name}
              adress={data.adress}
              tel={data.tel}
              description={data.description}
            />
          </div>
        )
      }
    })
  }

  render() {
    return (
      <>
        <Title className="col-s-12 pt-half">Kuhmilchbauern</Title>
        {this.renderHelperKuh()}
        {this.props.num === 0
          ? <Title className="col-s-12 pt">Ziegenmilchbauern</Title>
          : this.props.limit
            ? null
            : <Title className="col-s-12 pt">Ziegenmilchbauern</Title>
        }
        {this.renderHelperZiegen()}
      </>
    );
  }
}


const mapStateToProps = (data) => {
  return {
    loadedK: data.loaded.kuhmilchbauern,
    kuhmilchbauern: data.bauern.kuhmilchbauern,
    loadedZ: data.loaded.ziegenmilchbauern,
    ziegenmilchbauern: data.bauern.ziegenmilchbauern
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(BauernRender)
