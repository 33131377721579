import React, { Component } from 'react';

export class Accordion extends Component {
  render() {
    return (
      <div className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}
export class Panel extends Component {
  state = {
    isOpen: false
  }

  toggle = () => {
    this.setState({isOpen: !this.state.isOpen})
    const myId = document.getElementById(this.props.id);
    if(!this.state.isOpen) {
      console.log('hi');
      myId.style.height = myId.scrollHeight + 'px';
    } else {
      myId.style.height = '0px';
    }

  }

  render() {
    return (
      <div className={this.props.className} onClick={this.toggle}>
        <div className={this.state.isOpen ? "wrapper active" : "wrapper"}>
          <p className={this.props.titleStyle}>{this.props.title}<span className="arrow"></span></p>
        </div>
        <div id={this.props.id} className="transition panel">
          {this.props.children}
        </div>
      </div>
    );
  }
}
