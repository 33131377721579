import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetch } from "../../../../redux/actions";
import Helmet from 'react-helmet';

import { PageContainer } from '../../../reusable/styled/container';
import { Header } from '../../../reusable/styled/header';
import ProduktRender from './produktRender';

import Logo from '../../../../media/logos/Logo.svg';

import BigImg from '../../../../media/header/header_ziegenmilch_big.jpg';
import MiddleImg from '../../../../media/header/header_ziegenmilch_medium.jpg';
import SmallImg from '../../../../media/header/header_ziegenmilch_small.jpg';

import Zertifikat from '../../../../media/sonnenalm_bio-zertifikat.pdf';

import { ROOT_URL } from "../../../../config";

class Ziegenmilch extends Component {

    componentDidMount() {
    if (!this.props.loaded) {
      this.props.fetch(
        "fetch_goatmilk",
        `${ROOT_URL}/api/singletons/get/goatmilkproducts?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
  }

  openPdf = function() {
    window.open(Zertifikat);
  }
render() {
return (
  <PageContainer>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Das beste von der Ziege | Ziegenmilchprodukte - Sonnenalm</title>
      <meta
        name="description"
        content="Vom Hof ins Kühlregal - Genuss in seiner natürlichsten Form: Dafür stehen die Sonnenalm-Milchprodukte."
      />
      <link
        rel="canonical"
        href="http://www.milcherlebniswelt.at/produkte/ziegenmilchprodukte"
      />
    </Helmet>

    <div className="divider-2">
      <Header
        imageB={BigImg}
        imageM={MiddleImg}
        imageS={SmallImg}
        logo={Logo}
        title="Ziegenmilch"
      ></Header>
    </div>

    <div className="container-big" style={{ paddingTop: "35px" }}>
      <hr className="center" />
      <h1 className="set-font-sp align-center">
        {this.props.loaded && this.props.goatmilk.title}
      </h1>
      <hr className="center" />
      <h2
        className="small set-font-primary align-center"
        style={{ fontWeight: "700", paddingTop: "30px" }}
      >
        {this.props.goatmilk.subTitle}
      </h2>
      <div className="align-center" style={{ paddingTop: "30px" }}>
        {this.props.loaded && (
          <div
            className="container nop align-center"
            dangerouslySetInnerHTML={{
              __html: this.props.goatmilk.text
            }}
          ></div>
        )}
      </div>
    </div>

    <div className="container-big nop">
      <div className="row pt-half">
        <ProduktRender />
      </div>
    </div>
  </PageContainer>
);
}

};

const mapStateToProps = data => {
  return {
    goatmilk: data.goatmilk,
    loaded: data.loaded.goatmilk
  };
};

export default connect(mapStateToProps, { fetch })(Ziegenmilch);
