import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROOT_URL } from '../../../../config';
import { fetch } from '../../../../redux/actions';

import { Helmet } from 'react-helmet';
import { Title, Paragraph } from '../../../reusable/styled/fonts';
import { PageContainer, HeadlineContainer } from '../../../reusable/styled/container';
import Faq from './faq';
import { Header } from '../../../reusable/styled/header';
import DownloadsRender from './downloads';
import ProduktRender from './produkte/produktRender';

import { Phone, Mail, Fax } from '../../../../media/icons/file';
import Logo from '../../../../media/logos/Logo.svg';

import BigImg from '../../../../media/header/header_schulmilch2_big.jpg';
import MiddleImg from '../../../../media/header/header_schulmilch2_medium.jpg';
import SmallImg from '../../../../media/header/header_schulmilch2_small.jpg';

import Fade from 'react-reveal/Fade';


class Schulmilch extends Component {
  componentDidMount() {
    if(!this.props.loaded) {
      this.props.fetch('fetch_contact_settings', `${ROOT_URL}/api/collections/get/contactsettings?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  render() {
    return (
      <PageContainer className="xy-hidden">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nur das beste für die Schüler | Schulmilch - Sonnenalm</title>
          <meta
            name="description"
            content="Wir haben uns seit 1997 auf die Belieferung von Schulen und Kindergärten spezialisiert. Mit über 120 Schulen und Kindergärten als Kunden und einem auf Kinder abgestimmten BIO-Schulmilchsortiment, zählen wir mittlerweile österreichweit zu den größten Schulmilchanbietern."
          />
          <link
            rel="canonical"
            href="http://www.milcherlebniswelt.at/produkte/schulmilch"
          />
        </Helmet>

        {/* Header */}
        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Schulmilchaktion"
          ></Header>
        </div>

        <div className="container-big" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded2 && this.props.schoolmilk.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.schoolmilk.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded2 && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.schoolmilk.description
                }}
              ></div>
            )}
          </div>
        </div>

        {/* Schulmilchprodukte auflistung */}
        <div className="divider-1">
          <div className="container-big" style={{ paddingTop: "50px" }}>
            <div className="row">
              <ProduktRender />
            </div>
            <div className="row">
              <div className="col-s-12">
                <Paragraph>
                  *Preise durch Europäisches Schulmilchprogramm begünstigt! Alle
                  Preise inkl. Steuern und Abgaben, frei Haus geliefert!
                </Paragraph>
              </div>
            </div>
          </div>
        </div>

        {/* Bestellbereich */}
        <div className="bg-tertiary-light">
          <div className="container-big">
            <div className="row">
              <div className="col-s-12 nopb">
                <Fade>
                  <HeadlineContainer>
                    <Title className="align-center">
                      <span className="black">SO</span> bestellen Sie Ihre{" "}
                      <br />
                      Schulmilch
                    </Title>
                  </HeadlineContainer>
                </Fade>
              </div>
              <div className="col-s-12 col-md-3">
                <Fade>
                  <Paragraph>
                    Bestellungen sind per Telefon, Fax oder E-Mail möglich
                  </Paragraph>
                  <div className="container nopl nopr nopb ">
                    <div className="row flex-align-center container nopl nopr nopt">
                      <Phone className="icon IE11ICONFIXP" />
                      <p>{this.props.loaded && this.props.contact.tel}</p>
                    </div>
                    <div className="row flex-align-center container nopl nopr nopt">
                      <Fax className="icon IE11ICONFIXF" />
                      <p>{this.props.loaded && this.props.contact.fax}</p>
                    </div>
                    <div className="row flex-align-center container nopl nopr nopt">
                      <Mail className="icon IE11ICONFIXM" />
                      <p>{this.props.loaded && this.props.contact.mail}</p>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="col-md-1"></div>
              <div className="col-s-12 col-md-7 nop">
                <Fade>
                  <div className="row">
                    <DownloadsRender />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>

        {/* FAQ */}
        <div className="divider-2">
          <div className="container-big ">
            <div className="row">
              <div className="col-s-12 nopb">
                <Fade>
                  <HeadlineContainer>
                    <Title className="align-center">
                      <span className="black">SO</span> sind unsere
                      <br />
                      Liefer- und Zahlungsbedingungen FAQ
                    </Title>
                  </HeadlineContainer>
                </Fade>
              </div>

              <div className="col-s-12 nopt">
                <Fade>
                  <div className="container-middle-big nop">
                    <Faq />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}

const mapStateToProps = (data) => {
  return {
    loaded: data.loaded.contact,
    loaded2: data.loaded.schoolmilk,
    contact: data.contactSettings,
    schoolmilk: data.schoolmilk
  };
}

export default connect(
  mapStateToProps,
  { fetch }
)(Schulmilch)
