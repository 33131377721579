import { FETCH_KUHMILCHBAUERN} from '../actions/type';
import { FETCH_ZIEGENMILCHBAUERN} from '../actions/type';

const initState = {}

export default function(state = initState, action) {
  switch(action.type) {
    case FETCH_KUHMILCHBAUERN:
      return {...state, kuhmilchbauern: action.data.entries};
    case FETCH_ZIEGENMILCHBAUERN:
      return {...state, ziegenmilchbauern: action.data.entries};
    default:
      return state;
  }
}
