import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROOT_URL } from '../../../config';
import { fetch } from '../../../redux/actions';
import VertriebsPartner from './partner';
import { Panel } from '../../reusable/styled/accordion';

class PartnerRender extends Component {
  componentDidMount() {
    if(!this.props.loaded) {
      this.props.fetch('fetch_partner', `${ROOT_URL}/api/collections/get/partner?token=b0e71c309917ffc064b1a14ea6a6cd`)
    }
  }

  renderHelper = () => {
    // if data isnt loaded exit the function
    if(!this.props.loaded ) { return }
    // if data is loaded render Produkts
    return this.props.vertriebspartner.map((data, i) => {
      return (
        <div key={i} className="col-lg-4 col-md-4 col-ms-6">
          <Panel id={i} title={data.bezirk} className="card-border pointer hoverAcc" titleStyle="primary">
            <VertriebsPartner
              name={data.partner}
              link={data.link}
              loaded={this.props.loaded}
            />
          </Panel>
        </div>
      )
    })
  }

  render() {
    return (
      <>
        {this.renderHelper()}
      </>
    );
  }
}


const mapStateToProps = (data) => {
  return {
    loaded: data.loaded.partner,
    vertriebspartner: data.partner,
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(PartnerRender)
