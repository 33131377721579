import React, { Component } from 'react';
import Produkt from './ziegenmilch-produkt';
import { connect } from 'react-redux';
import { ROOT_URL } from '../../../../config';
import { fetch } from '../../../../redux/actions';

class ProduktRender extends Component {
  state = {
    width: window.innerWidth
  };

  componentDidMount() {
    if (!this.props.loaded) {
      this.props.fetch(
        "fetch_ziegenmilchprodukte",
        `${ROOT_URL}/api/collections/get/ziegenmilchprodukte?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  renderHelper = () => {
    // if data isnt loaded exit the function
    if (!this.props.loaded) {
      return;
    }
    // if data is loaded render Produkts
    return this.props.ziegenmilchprodukte.map((data, i) => {
      return (
        <div key={i} className="col-lg-3 col-md-4 col-ms-6">
          <Produkt
            image={data.image.path}
            width={this.state.width}
            produktName={data.produktname}
            subTitle={data.subtitle}
            tag={data.tag}
            price={data.price}
            amount={data.amount}
            naehrwerte={data.naehrwerte}
            allergene={data.allergene}
          />
        </div>
      );
    });
  };

  render() {
    return <>{this.renderHelper()}</>;
  }
}


const mapStateToProps = (data) => {
  return {
    loaded: data.loaded.ziegenmilchprodukte,
    ziegenmilchprodukte: data.ziegenmilchprodukte
  }
}

export default connect(
  mapStateToProps,
  { fetch }
)(ProduktRender)
