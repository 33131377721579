import React, { Component } from "react";
import { connect } from "react-redux";
import { fetch } from "../../../../redux/actions";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Title, SubTitle } from "../../../reusable/styled/fonts";
import {
  PageContainer,
  HeadlineContainer
} from "../../../reusable/styled/container";
import { PageLink } from "../../../reusable/styled/pageLink";
import { Header } from "../../../reusable/styled/header";
import Logo from "../../../../media/logos/Logo.svg";

import SchulmilchProdukte from "../../../../media/images/Hofladen_SchulmilchButton.png";
import ZiegenProdukte from "../../../../media/images/Hofladen_ZiegenmilchButton.png";
import KuhProdukte from "../../../../media/images/Hofladen_KuhmilchButton.png";
import BigImg from "../../../../media/header/header_hofladen_big.jpg";
import MiddleImg from "../../../../media/header/header_hofladen_medium.jpg";
import SmallImg from "../../../../media/header/header_hofladen_small.jpg";
import Öffnungszeiten from "../../../../media/header/Offnungszeiten.jpg";

import Fade from "react-reveal/Fade";

import { ROOT_URL } from "../../../../config";

class Hofladen extends Component {
  componentDidMount() {
    if (!this.props.loaded || !this.props.loadedOpentimes) {
      this.props.fetch(
        "fetch_store",
        `${ROOT_URL}/api/singletons/get/store?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
      this.props.fetch(
        "fetch_opentimes_settings",
        `${ROOT_URL}/api/singletons/get/opentimes?token=b0e71c309917ffc064b1a14ea6a6cd`
      );
    }
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Kaufen Sie unsere Produkte direkt | Hofladen - Sonnenalm
          </title>
          <meta
            name="description"
            content="Ab Hof-Laden | Nutzen Sie die Gelegenheit und holen Sie sich Ihre frische Kuh- und Ziegenmilchprodukte direkt vom Bäuerlichen Milchhof - frischer gehts nicht! Gleichzeitig haben Sie die einmalige Gelegenheit in unserer Milcherlebniswelt dem Käser über die Schulter zu schauen."
          />
          <link
            rel="canonical"
            href="http://www.milcherlebniswelt.at/ab-hof-verkauf"
          />
        </Helmet>

        <div className="divider-2">
          <Header
            imageB={BigImg}
            imageM={MiddleImg}
            imageS={SmallImg}
            logo={Logo}
            title="Hofladen"
          ></Header>
        </div>

        <div className="container-big" style={{ paddingTop: "35px" }}>
          <hr className="center" />
          <h1 className="set-font-sp align-center">
            {this.props.loaded && this.props.store.title}
          </h1>
          <hr className="center" />
          <h2
            className="small set-font-primary align-center"
            style={{ fontWeight: "700", paddingTop: "30px" }}
          >
            {this.props.store.subTitle}
          </h2>
          <div className="align-center" style={{ paddingTop: "30px" }}>
            {this.props.loaded && (
              <div
                className="container nop align-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.store.description
                }}
              ></div>
            )}
          </div>
        </div>

        <div className="divider-1">
          <div className="container-big pt-half">
            <div className="row">
              <div className="col-s-12">
                <div className="row justify-center flex-align-center">
                  <div className="col-md-4 nop">
                    <div
                      className="row tertiary-light"
                      style={{
                        boxShadow: "0 0 99px rgba(0,0,0,0.2)",
                        borderRadius: "16px"
                      }}
                    >
                      <img
                        alt="Öffnungszeiten Bild"
                        style={{ borderRadius: "16px" }}
                        src={Öffnungszeiten}
                      />
                      <SubTitle className="col-s-12 nopb primary-dark bold">
                        Öffnungszeiten
                      </SubTitle>
                      <div className="col-s-6 nopb">
                        <p className="primary-dark bold">
                          {this.props.loadedOpentimes &&
                            this.props.opentimes.days}
                        </p>
                      </div>
                      <div className="col-s-6 nopb">
                        <p className="primary-dark bold">
                          {this.props.loadedOpentimes &&
                            this.props.opentimes.time}
                        </p>
                      </div>
                      <div className="col-s-6 nopt">
                        <p className="primary-dark bold">
                          {this.props.loadedOpentimes &&
                            this.props.opentimes.days2}
                        </p>
                      </div>
                      <div className="col-s-6 nopt">
                        <p className="primary-dark bold">
                          {this.props.loadedOpentimes &&
                            this.props.opentimes.time2}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 3 Bilder nebeineinander am ende der Seite mit verlinkung zu anderen seiten */}
        <div className="">
          <div className="container-big pt padding-bottom-kaese">
            <div className="row">
              <div className="col-s-12 nop">
                <div className="row justify-center">
                  <div className="col-md-4 col-s-6 nop">
                    <NavLink onClick={() => window.scroll({ top: 0 })}>
                      <PageLink
                        to="/produkte/kuhmilchprodukte"
                        backgroundImage={KuhProdukte}
                      >
                        <p className="pageLinkText align-center">Kuhmilch</p>
                      </PageLink>
                    </NavLink>
                  </div>
                  <div className="col-md-4 col-s-6 nop">
                    <NavLink onClick={() => window.scroll({ top: 0 })}>
                      <PageLink
                        to="/produkte/ziegenmilchprodukte"
                        backgroundImage={ZiegenProdukte}
                      >
                        <p className="pageLinkText align-center">Ziegenmilch</p>
                      </PageLink>
                    </NavLink>
                  </div>
                  <div className="col-md-4 col-s-6 nop">
                    <NavLink onClick={() => window.scroll({ top: 0 })}>
                      <PageLink
                        to="/produkte/schulmilch"
                        backgroundImage={SchulmilchProdukte}
                      >
                        <p className="pageLinkText align-center">Schulmilch</p>
                      </PageLink>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    );
  }
}
// connect to redux store
const mapStateToProps = data => {
  return {
    store: data.store,
    loaded: data.loaded.store,
    opentimes: data.opentimesSettings,
    loadedOpentimes: data.loaded.opentimes
  };
};

export default connect(mapStateToProps, { fetch })(Hofladen);
