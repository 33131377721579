import React from 'react';

import { SubTitle } from '../../../../reusable/styled/fonts';

import { ROOT_URL_ORIGIN } from '../../../../../config';

export const ModalBoxContent = (props) => {
  const renderHelper = function() {
    const { description } = props;
    if(description !== undefined) {
      return (
        <div className="padding-modal-produkt">
          <div className="closeBtn-mobile"></div>
          <img alt={props.name} className="bauernImage-m" src={ROOT_URL_ORIGIN + props.image} />
          <div className="container nopb">
            <SubTitle>{props.name}</SubTitle>
          </div>
          <div className="row">
            <div className="col-lg-9 nopl">
              <div dangerouslySetInnerHTML={{ __html: props.description }} className="br"></div>
            </div>
            <div className="col-lg-3 nopl">
              <p>{props.adress} <br /> {props.tel}</p>
            </div>
          </div>
          <p className="primary-light">schließen</p>
        </div>
      )

    }
  }
  return (
    <div className={"modalboxContent-full flexbox justify-center flex-align-center pointer " + props.animation} onClick={props.close}>
      <div className="modallist-container-full ">
        {renderHelper()}
      </div>
    </div>
  )
}
