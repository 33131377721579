import React from 'react';

import Modalbox from '../../../../reusable/helper/functional/modalbox';
import { ModalBoxContent } from './modalContent';

export const ModalboxContainer = (props) => (
  <Modalbox
    delayTime={500}
    isMounted={props.isMountedN}
    modalContent={
      <ModalBoxContent
        close={props.close}
        naehrwerte={props.naehrwerte}
        animation={props.animation}
      />
    }>
  </Modalbox>
);
